import React, { useState, useCallback, useEffect } from 'react';
import { useLoadScript, GoogleMap, Marker, Autocomplete } from '@react-google-maps/api';
import { createPost } from '../lib/adminapi';
import { FaClock, FaCalendarAlt, FaArrowUp, FaPlus, FaMinus } from 'react-icons/fa';
import Swal from 'sweetalert2';
import Header from '../components/header';
import { getPresignedUrl, uploadFileToS3 } from '../lib/adminapi';
import Footer2 from '../components/footer2';

// Define libraries to load
const libraries = ['places'];

// Map container style
const mapContainerStyle = {
    width: '100%',
    height: '400px',
};

// Default center (e.g., Amman, Jordan)
const defaultCenter = {
    lat: 31.963158,
    lng: 35.930359,
};

// Options for the Google Map
const options = {
    disableDefaultUI: true,
    zoomControl: true,
};

async function uploadThumbnail(thumbnailFile) {
    try {
        console.log('thumbnailFile.type:', thumbnailFile.type);
        const { url, fields, key } = await getPresignedUrl(
            thumbnailFile.name,
            thumbnailFile.type
        );

        // Step 2: Prepare the form data for S3 upload
        const formDataImage = new FormData();
        Object.entries(fields).forEach(([fieldKey, fieldValue]) => {
            formDataImage.append(fieldKey, fieldValue);
        });
        formDataImage.append('file', thumbnailFile);

        // Step 3: Upload the file directly to S3
        await uploadFileToS3(url, formDataImage);

        // Step 4: Construct the file URL
        const imageUrl = `${url}/${key}`;
        return imageUrl;
    } catch (error) {
        console.error('Error uploading thumbnail:', error);
        throw error;
    }
}

function CreateEvent() {
    const [formData, setFormData] = useState({
        title: '',
        thumbnail: '',
        venue: '',
        date: '',
        startTime: '',
        endTime: '',
        description: '',
        genre: '',
        currency: 'JOD',
        djname: '',
        location: '',
        country: '',
        state: '',
        city: '',
    });
    const [tickets, setTickets] = useState([
        { type: '', quantity: '', price: '' },
    ]); // New state for dynamic tickets
    const [showScroll, setShowScroll] = useState(false);
    const [thumbnailFile, setThumbnailFile] = useState(null);
    const [markerPosition, setMarkerPosition] = useState(null);
    const [autocomplete, setAutocomplete] = useState(null);

    const { isLoaded, loadError } = useLoadScript({
        googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY,
        libraries,
    });

    const onMapClick = useCallback((event) => {
        const lat = event.latLng.lat();
        const lng = event.latLng.lng();
        setMarkerPosition({ lat, lng });
        const googleMapsUrl = `https://www.google.com/maps?q=${lat},${lng}`;
        setFormData((prevData) => ({ ...prevData, location: googleMapsUrl }));
    }, []);

    const onAutocompleteLoad = (autoC) => {
        setAutocomplete(autoC);
    };
    const handleScroll = () => {
        if (window.scrollY > 300) {
            setShowScroll(true);
        } else {
            setShowScroll(false);
        }
    };

    useEffect(() => {
        window.addEventListener('scroll', handleScroll);
        return () => window.removeEventListener('scroll', handleScroll);
    }, []);
    const scrollToTop = () => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
    };

    const onPlaceChanged = () => {
        if (autocomplete !== null) {
            const place = autocomplete.getPlace();
            if (place.geometry) {
                const lat = place.geometry.location.lat();
                const lng = place.geometry.location.lng();
                setMarkerPosition({ lat, lng });
                setFormData((prevData) => ({
                    ...prevData,
                    location: `https://www.google.com/maps?q=${lat},${lng}`,
                }));
            } else {
                alert('No details available for the selected location.');
            }
        } else {
            console.log('Autocomplete is not loaded yet!');
        }
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };

    const handleThumbnailChange = (e) => {
        const file = e.target.files[0];
        if (file) {
            if (file.type.startsWith('image/')) {
                setThumbnailFile(file);
            } else {
                alert('Please select a valid image file.');
                e.target.value = ''; // Reset the input value
            }
        }
    };

    // Utility function to convert 24-hour time to 12-hour format with AM/PM
    function convertTo12Hour(time24) {
        const [hour, minute] = time24.split(':').map(Number);
        const ampm = hour >= 12 ? 'pm' : 'am';
        const hour12 = hour % 12 === 0 ? 12 : hour % 12;
        return `${hour12}:${minute.toString().padStart(2, '0')}${ampm}`;
    }
    const handleTicketChange = (index, field, value) => {
        const updatedTickets = tickets.map((ticket, i) =>
            i === index ? { ...ticket, [field]: value } : ticket
        );
        setTickets(updatedTickets);
    };

    const addTicket = () => {
        setTickets([...tickets, { type: '', quantity: '', price: '' }]);
    };

    const removeTicket = (index) => {
        const updatedTickets = tickets.filter((_, i) => i !== index);
        setTickets(updatedTickets);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        for (let i = 0; i < tickets.length; i++) {
            const { type, quantity, price } = tickets[i];
            if (!type.trim()) {
                alert(`Ticket type at position ${i + 1} is required.`);
                return;
            }
            const qty = parseInt(quantity, 10);
            const prc = parseFloat(price);
            if (isNaN(qty) || qty < 0) {
                alert(`Invalid quantity for ticket type at position ${i + 1}.`);
                return;
            }
            if (isNaN(prc) || prc < 0) {
                alert(`Invalid price for ticket type at position ${i + 1}.`);
                return;
            }
        }
        const convertedData = { ...formData };


        const startTime12 = convertTo12Hour(formData.startTime);
        const endTime12 = convertTo12Hour(formData.endTime);
        // Combine startTime and endTime into a single string
        convertedData.time = `${startTime12}-${endTime12}`;
        delete convertedData.startTime;
        delete convertedData.endTime;

        convertedData.tickets = tickets.map((ticket) => ({
            type: ticket.type,
            quantity: parseInt(ticket.quantity, 10),
            price: parseFloat(ticket.price),
        }));

        if (markerPosition) {
            convertedData.latitude = markerPosition.lat;
            convertedData.longitude = markerPosition.lng;
        } else {
            Swal.fire({
                icon: 'error',
                title: 'Please select a location using the search or by clicking on the map.',
            });
            return;
        }
        // Upload thumbnail if a file is selected
        if (thumbnailFile) {
            try {
                const imageUrl = await uploadThumbnail(thumbnailFile);
                convertedData.thumbnail = imageUrl;
            } catch (error) {
                alert('Error, make sure thumbnail is an image.');
                return;
            }
        } else {
            alert('Thumbnail image is required.');
            return;
        }

        // Set location URL
        if (formData.location) {
            // Already set during place selection or map click
        } else {
            Swal.fire({
                icon: 'error',
                title: 'Please select a location using the search or by clicking on the map.',
            });
            return;
        }
        const result = await Swal.fire({
            title: 'Are you sure you want to create this event?',
            text: 'Please double-check all the details as this will reflect on the VENT application.',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#28a745', // Green color for "Yes" button
            cancelButtonColor: '#d33',     // Red color for "No" button
            confirmButtonText: 'Yes, I\'m sure',
            cancelButtonText: 'No, I\'m not',
        });

        // Check if the user confirmed
        if (!result.isConfirmed) {
            // User clicked 'No, I'm not', so just exit the function
            return;
        }
        try {
            const response = await createPost(convertedData);
            if (response.success) {
                await Swal.fire({
                    title: 'Event created successfully, waiting to be reviewed!',
                    icon: 'success',
                    confirmButtonColor: '#28a745',
                });
                // Reset form
                setFormData({
                    title: '',
                    thumbnail: '',
                    venue: '',
                    date: '',
                    startTime: '',
                    endTime: '',
                    description: '',
                    genre: '',
                    currency: 'JOD',
                    djname: '',
                    location: '',
                    country: '',
                    state: '',
                    city: '',
                });
                setTickets([{ type: '', quantity: '', price: '' }]);
                setThumbnailFile(null);
                setMarkerPosition(null);
                scrollToTop();
            } else {
                alert(`Error: ${response.error}`);
            }
        } catch (error) {
            console.error('Error creating post:', error);
            alert('An unexpected error occurred.');
        }
    };

    const handleCancel = () => {
        // Reset form
        setFormData({
            title: '',
            thumbnail: '',
            venue: '',
            date: '',
            startTime: '',
            endTime: '',
            description: '',
            genre: '',
            currency: 'JOD',
            djname: '',
            location: '',
            country: '',
            state: '',
            city: '',
        });
        setThumbnailFile(null);
        setTickets([{ type: '', quantity: '', price: '' }]);
        setMarkerPosition(null);
        scrollToTop();
    };

    if (loadError) return <div>Error loading maps</div>;
    if (!isLoaded) return <div>Loading Maps...</div>;

    return (
        <div className="bg-[#211d3a] flex flex-col overflow-x-hidden">
            <Header />
            <div className="flex-grow flex justify-center items-center min-h-screen bg-[#211d3a] p-4">
                {showScroll && (
                    <button
                        className="fixed bottom-4 right-4 text-white p-3 hover:text-[#574bfc] "
                        onClick={scrollToTop}
                        title="Scroll to top"
                    >
                        <FaArrowUp size={24} />
                    </button>
                )}
                {/* Main Content */}
                <div className="w-full max-w-screen-2xl ">
                    {/* Headers outside the form */}
                    <h1 className="md:text-3xl text-xl text-white font-bold mb-2">Create a new Vent</h1>
                    <h2 className="md:text-xl text-base text-[#626b83] font-bold mb-6">
                        Please provide the required details to create an event
                    </h2>
                    {/* Form */}
                    <form onSubmit={handleSubmit}>
                        {/* Input Fields Div */}
                        <div className="bg-[#262549] p-8 rounded-lg">
                            {/* Group 1: Title, Venue, Date */}
                            <div className="flex flex-wrap -mx-4">
                                {/* Title */}
                                <div className="w-full md:w-1/3 px-4 mb-6">
                                    <label className="block text-xl font-semibold text-white mb-2">Event Title *</label>
                                    <input
                                        type="text"
                                        name="title"
                                        placeholder="Event Title (keep it short!)"
                                        value={formData.title}
                                        onChange={handleChange}
                                        required
                                        className="w-full px-4 py-3 border border-[#3c395d] rounded-xl md:text-xl text-base bg-white text-black placeholder-[#96a1b7]
                                        focus:ring-2 focus:ring-[#574bfc] focus:border-[#574bfc] focus:outline-none"
                                    />
                                </div>
                                {/* Venue */}
                                <div className="w-full md:w-1/3 px-4 mb-6">
                                    <label className="block text-xl font-semibold text-white mb-2">Venue *</label>
                                    <input
                                        type="text"
                                        name="venue"
                                        placeholder="Venue"
                                        value={formData.venue}
                                        onChange={handleChange}
                                        required
                                        className="w-full px-4 py-3 border border-[#3c395d] rounded-xl md:text-xl text-base bg-white text-black placeholder-[#96a1b7]
                                        focus:ring-2 focus:ring-[#574bfc] focus:border-[#574bfc] focus:outline-none"
                                    />
                                </div>
                                {/* Date */}
                                <div className="w-full md:w-1/3 px-4 mb-6">
                                    <div className="relative">
                                        <label className="block text-xl font-semibold text-white mb-2">Date *</label>
                                        <input
                                            type="date"
                                            name="date"
                                            value={formData.date}
                                            onChange={(e) => {
                                                const selectedDate = e.target.value;
                                                const today = new Date().toISOString().split('T')[0];

                                                if (selectedDate < today) {
                                                    // Prevent past dates
                                                    alert("You cannot select a past date.");
                                                    e.target.value = ""; // Clear invalid input
                                                    return;
                                                }

                                                handleChange(e); // Call your existing handleChange function
                                            }}
                                            required
                                            min={new Date().toISOString().split('T')[0]}  // Prevents selecting past dates in the picker
                                            className="w-full px-4 py-3 border border-[#3c395d] md:text-xl text-base rounded-xl bg-white text-black placeholder-[#96a1b7]
            focus:ring-2 focus:ring-[#574bfc] focus:border-[#574bfc] focus:outline-none appearance-none"
                                        />
                                        <FaCalendarAlt className="absolute right-4 top-10 text-[#96a1b7] pointer-events-none" />
                                    </div>
                                </div>
                            </div>
                            {/* Group 2: Start Time, End Time, Price */}
                            <div className="flex flex-wrap -mx-4">
                                {/* Start Time */}
                                <div className="w-full md:w-1/3 px-4 mb-6">
                                    <div className="relative">
                                        <label className="block font-semibold text-xl text-white mb-2">Start Time *</label>
                                        <input
                                            type="time"
                                            name="startTime"
                                            value={formData.startTime}
                                            onChange={handleChange}
                                            required
                                            className="w-full px-4 py-3 border border-[#3c395d] md:text-xl text-base rounded-xl bg-white text-black placeholder-[#96a1b7]
                                            focus:ring-2 focus:ring-[#574bfc] focus:border-[#574bfc] focus:outline-none appearance-none"
                                        />
                                        <FaClock className="absolute right-4 top-10 text-[#96a1b7] pointer-events-none" />
                                    </div>
                                </div>
                                {/* End Time */}
                                <div className="w-full md:w-1/3 px-4 mb-6">
                                    <div className="relative">
                                        <label className="block font-semibold text-xl text-white mb-2">End Time *</label>
                                        <input
                                            type="time"
                                            name="endTime"
                                            value={formData.endTime}
                                            onChange={handleChange}
                                            required
                                            className="w-full px-4 py-3 border border-[#3c395d] md:text-xl text-base rounded-xl bg-white text-black placeholder-[#96a1b7]
                                            focus:ring-2 focus:ring-[#574bfc] focus:border-[#574bfc] focus:outline-none appearance-none"
                                        />
                                        <FaClock className="absolute right-4 top-10 text-[#96a1b7] pointer-events-none" />
                                    </div>
                                </div>
                                <div className="w-full md:w-1/3 px-4 mb-6">
                                    <label className="block font-semibold text-xl text-white mb-2">DJ Name / Presenter *</label>
                                    <input
                                        type="text"
                                        name="djname"
                                        placeholder="DJ Name / Presenter"
                                        value={formData.djname}
                                        onChange={handleChange}
                                        required
                                        className="w-full px-4 py-3 md:text-xl text-base border border-[#3c395d] rounded-xl bg-white text-black placeholder-[#96a1b7]
                                        focus:ring-2 focus:ring-[#574bfc] focus:border-[#574bfc] focus:outline-none"
                                    />
                                </div>
                            </div>
                            {/* Group 3: Tickets, DJ Name, Genre */}
                            <div className="flex flex-wrap -mx-4">
                                {/* Genre */}
                                <div className="w-full px-4 mb-6">
                                    <label className="block font-semibold text-xl text-white mb-2">Genre *</label>
                                    <input
                                        type="text"
                                        name="genre"
                                        placeholder="If multiple, separate by commas"
                                        value={formData.genre}
                                        onChange={handleChange}
                                        required
                                        className="md:text-xl text-base w-full px-4 py-3 border border-[#3c395d] rounded-xl bg-white text-black placeholder-[#96a1b7]
                                        focus:ring-2 focus:ring-[#574bfc] focus:border-[#574bfc] focus:outline-none"
                                    />
                                </div>
                            </div>
                            {/* Group 5: Dynamic Tickets */}
                            <div className="px-4 rounded-lg mb-6 -mx-4">
                                <div className="flex justify-between items-center mb-4">
                                    <h3 className="text-xl font-semibold text-white">Tickets *  </h3>
                                    <button
                                        type="button"
                                        onClick={addTicket}
                                        className="flex items-center justify-center bg-[#4954a5] text-white p-2 rounded-full hover:bg-[#574bfc]"
                                        title="Add Ticket"
                                    >
                                        <FaPlus />
                                    </button>
                                </div>
                                {tickets.map((ticket, index) => (
                                    <div key={index} className="flex flex-wrap -mx-2 mb-4 items-end">
                                        {/* Ticket Type */}
                                        <div className="w-full md:w-1/3 px-2 mb-4 md:mb-0">
                                            <label className="block font-semibold text-xl text-white mb-2">Ticket Type *</label>
                                            <input
                                                type="text"
                                                placeholder="e.g., VIP, Early Bird"
                                                value={ticket.type}
                                                onChange={(e) => handleTicketChange(index, 'type', e.target.value)}
                                                required
                                                className="md:text-xl text-base w-full px-4 py-3 border border-[#3c395d] rounded-xl bg-white text-black placeholder-[#96a1b7]
                                        focus:ring-2 focus:ring-[#574bfc] focus:border-[#574bfc] focus:outline-none"
                                            />
                                        </div>
                                        {/* Quantity */}
                                        <div className="w-full md:w-1/3 px-2 mb-4 md:mb-0">
                                            <label className="block font-semibold text-xl text-white mb-2">Quantity *</label>
                                            <input
                                                type="number"
                                                placeholder="Quantity"
                                                value={ticket.quantity}
                                                onChange={(e) => handleTicketChange(index, 'quantity', e.target.value)}
                                                required
                                                min="1"
                                                className="md:text-xl text-base w-full px-4 py-3 border border-[#3c395d] rounded-xl bg-white text-black placeholder-[#96a1b7]
                                        focus:ring-2 focus:ring-[#574bfc] focus:border-[#574bfc] focus:outline-none"
                                            />
                                        </div>
                                        {/* Price */}
                                        <div className="w-full md:w-1/3 px-2 mb-4 md:mb-0">
                                            <label className="block font-semibold text-xl text-white mb-2">Price per ticket *</label>
                                            <input
                                                type="number"
                                                placeholder="Price"
                                                value={ticket.price}
                                                onChange={(e) => handleTicketChange(index, 'price', e.target.value)}
                                                required
                                                min="1"
                                                step="0.1"
                                                className="md:text-xl text-base w-full px-4 py-3 border border-[#3c395d] rounded-xl bg-white text-black placeholder-[#96a1b7]
                                        focus:ring-2 focus:ring-[#574bfc] focus:border-[#574bfc] focus:outline-none"
                                            />
                                        </div>
                                        {/* Remove Button */}
                                        <div className="w-full md:w-auto px-2">
                                            {tickets.length > 1 && (
                                                <button
                                                    type="button"
                                                    onClick={() => removeTicket(index)}
                                                    className="flex items-center justify-center bg-red-600 text-white p-2 rounded-full hover:bg-red-700 mt-5"
                                                    title="Remove Ticket"
                                                >
                                                    <FaMinus />
                                                </button>
                                            )}
                                        </div>
                                    </div>
                                ))}
                            </div>

                            {/* Event Description */}
                            <div className="mb-6">
                                <label className="block font-semibold md:text-xl text-base text-white mb-2">Event Description</label>
                                <textarea
                                    name="description"
                                    placeholder="(Optional)"
                                    value={formData.description}
                                    onChange={handleChange}
                                    rows="4"
                                    className="w-full px-4 py-3 border border-[#3c395d] md:text-xl text-base rounded-xl bg-white text-black placeholder-[#96a1b7]
                                    focus:ring-2 focus:ring-[#574bfc] focus:border-[#574bfc] focus:outline-none"
                                />
                            </div>
                            {/* Thumbnail Upload */}
                            <div className="mb-6">
                                <label className="block mb-2 text-xl font-semibold text-white">Thumbnail Image *</label>
                                <input
                                    type="file"
                                    accept="image/*"
                                    onChange={handleThumbnailChange}
                                    required
                                    className="w-full px-4 py-3 border border-[#3c395d] rounded-xl text-base md:text-xl bg-white text-black placeholder-[#96a1b7]
                                    focus:ring-2 focus:ring-[#574bfc] focus:border-[#574bfc] focus:outline-none"
                                />
                            </div>
                            {/* Location Search */}
                            <div className="mb-6">
                                <label className="block font-semibold text-xl text-white mb-2">
                                    Search location or select on map *
                                </label>
                                <Autocomplete onLoad={onAutocompleteLoad} onPlaceChanged={onPlaceChanged}>
                                    <input
                                        type="text"
                                        placeholder="Search"
                                        className="w-full px-4 py-3 md:text-xl text-base border border-[#3c395d] rounded-xl bg-white text-black placeholder-[#96a1b7]
                                        focus:ring-2 focus:ring-[#574bfc] focus:border-[#574bfc] focus:outline-none"
                                    />
                                </Autocomplete>
                            </div>
                            {/* Google Map */}
                            <div className="mb-6">
                                <GoogleMap
                                    mapContainerStyle={mapContainerStyle}
                                    zoom={markerPosition ? 15 : 13}
                                    center={markerPosition || defaultCenter}
                                    options={options}
                                    onClick={onMapClick}
                                >
                                    {markerPosition && <Marker position={markerPosition} />}
                                </GoogleMap>
                                {formData.location && (
                                    <p className="mt-2 text-[#96a1b7] text-xl">
                                        Location selected:{' '}
                                        <a
                                            href={formData.location}
                                            target="_blank"
                                            rel="noopener noreferrer"
                                            className="underline"
                                        >
                                            {formData.location}
                                        </a>
                                    </p>
                                )}
                            </div>
                            {/* Group 4: Country, State, City */}
                            <div className="flex flex-wrap -mx-4">
                                {/* Country */}
                                <div className="w-full md:w-1/3 px-4 mb-6">
                                    <label className="block font-semibold text-xl text-white mb-2">Country *</label>
                                    <input
                                        type="text"
                                        name="country"
                                        placeholder="Country"
                                        value={formData.country}
                                        onChange={handleChange}
                                        required
                                        className="w-full px-4 py-3 text-base md:text-xl border border-[#3c395d] rounded-xl bg-white text-black placeholder-[#96a1b7]
                                        focus:ring-2 focus:ring-[#574bfc] focus:border-[#574bfc] focus:outline-none"
                                    />
                                </div>
                                {/* State */}
                                <div className="w-full md:w-1/3 px-4 mb-6">
                                    <label className="block font-semibold text-xl text-white mb-2">State</label>
                                    <input
                                        type="text"
                                        name="state"
                                        placeholder="State (if applicable)"
                                        value={formData.state}
                                        onChange={handleChange}
                                        className="w-full px-4 py-3 text-base md:text-xl border border-[#3c395d] rounded-xl bg-white text-black placeholder-[#96a1b7]
                                        focus:ring-2 focus:ring-[#574bfc] focus:border-[#574bfc] focus:outline-none"
                                    />
                                </div>
                                {/* City */}
                                <div className="w-full md:w-1/3 px-4 mb-6">
                                    <label className="block font-semibold text-xl text-white mb-2">City *</label>
                                    <input
                                        type="text"
                                        name="city"
                                        placeholder="City"
                                        value={formData.city}
                                        onChange={handleChange}
                                        required
                                        className="w-full px-4 py-3 border text-base md:text-xl border-[#3c395d] rounded-xl bg-white text-black placeholder-[#96a1b7]
                                        focus:ring-2 focus:ring-[#574bfc] focus:border-[#574bfc] focus:outline-none"
                                    />
                                </div>
                            </div>
                        </div>
                        {/* Buttons */}
                        <div className="flex justify-end mt-6 justify-center">
                            <button
                                type="button"
                                onClick={handleCancel}
                                className=" border-2 border-[#4954a5] hover:border-[#574bfc] text-white md:text-2xl text-lg md:py-3 md:px-6 px-3 py-1 rounded-full mr-4"
                            >
                                Cancel
                            </button>
                            <button
                                type="submit"
                                className="bg-[#4954a5] text-white hover:bg-[#574bfc] md:text-2xl text-lg md:py-3 md:px-6 px-3 py-1 rounded-full"
                            >
                                Create Vent
                            </button>
                        </div>
                    </form>
                </div>
            </div>
            <Footer2 />
        </div>
    );
}

export default CreateEvent;
