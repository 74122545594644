// components/EventAdminModal.js

import React, { useState, useEffect } from 'react';
import AttendeesModal from '../components/attendeesModal';
import TicketRevenueModal from '../components/ticketRevenueModal';
import { FaPen } from 'react-icons/fa';
import Swal from 'sweetalert2';
import {
    changeTitle,
    getTicketVariations, // New API function to fetch ticket variations
    changeTicketVariation,
    changeGenre,
    changeDescription,
    changeThumbnail, // Import the changeThumbnail function
    getPresignedUrl,
    uploadFileToS3
} from '../lib/adminapi'; // Adjust the import path as necessary

const EventModal = ({ event, onClose }) => {
    const [eventData, setEventData] = useState(event);
    const [isAttendeesModalOpen, setIsAttendeesModalOpen] = useState(false);
    const [isEditingTitle, setIsEditingTitle] = useState(false);
    const [newTitle, setNewTitle] = useState('');
    const [ticketVariations, setTicketVariations] = useState([]); // State for ticket variations
    const [updatedTicketData, setUpdatedTicketData] = useState({}); // Holds updates for the current ticket
    const [isEditingTickets, setIsEditingTickets] = useState(false);
    const [currentTicket, setCurrentTicket] = useState(null); // Holds the current ticket being edited
    const [isRevenueModalOpen, setRevenueModalOpen] = useState(false);


    const [isEditingGenre, setIsEditingGenre] = useState(false);
    const [newGenre, setNewGenre] = useState('');


    const [isEditingDescription, setIsEditingDescription] = useState(false);
    const [newDescription, setNewDescription] = useState('');

    const [isEditingThumbnail, setIsEditingThumbnail] = useState(false); // Add state for editing thumbnail
    const [newThumbnailFile, setNewThumbnailFile] = useState(null);

    const openRevenueModal = () => setRevenueModalOpen(true);
    const closeRevenueModal = () => setRevenueModalOpen(false);
    useEffect(() => {
        if (event) {
            setEventData(event);
            fetchTicketVariations(event.id);
        }
    }, [event]);

    useEffect(() => {
        if (isEditingTickets) {
            setUpdatedTicketData({ type: '', quantity: '' });
        }
    }, [isEditingTickets]);

    const fetchTicketVariations = async (eventId) => {
        try {
            const variations = await getTicketVariations(eventId);
            setTicketVariations(variations);
        } catch (error) {
            console.error('Error fetching ticket variations:', error);
            Swal.fire({
                icon: 'error',
                title: 'Error',
                text: 'Failed to fetch ticket variations.',
            });
        }
    };
    if (!eventData) return null;

    const {
        title,
        thumbnail,
        venue,
        date,
        time,
        genre,
        currency,
        djname,
        attending,
        location,
        city,
        state,
        country,
        description,
        id, // Assuming each event has a unique id
    } = eventData;

    const formatDate = (dateString) => {
        const date = new Date(dateString);
        if (isNaN(date.getTime())) return '';

        const day = date.getDate();
        const month = date.toLocaleString('default', { month: 'long' }); // "December"
        const year = date.getFullYear();

        return `${day} ${month}, ${year}`; // "11 December, 2024"
    };

    const handleBackgroundClick = (e) => {
        if (e.target === e.currentTarget) {
            onClose();
        }
    };

    const openAttendeesModal = () => {
        setIsAttendeesModalOpen(true);
    };

    const closeAttendeesModal = () => {
        setIsAttendeesModalOpen(false);
    };

    // Utility function to convert 24-hour time to 12-hour format with AM/PM


    async function uploadThumbnail(thumbnailFile) {
        try {
            const { url, fields, key } = await getPresignedUrl(
                thumbnailFile.name,
                thumbnailFile.type
            );

            // Prepare the form data for S3 upload
            const formDataImage = new FormData();
            Object.entries(fields).forEach(([fieldKey, fieldValue]) => {
                formDataImage.append(fieldKey, fieldValue);
            });
            formDataImage.append('file', thumbnailFile);

            // Upload the file directly to S3
            await uploadFileToS3(url, formDataImage);

            // Construct the file URL
            const imageUrl = `${url}/${key}`;
            return imageUrl;
        } catch (error) {
            console.error('Error uploading thumbnail:', error);
            throw error;
        }
    }

    // Handle Save Thumbnail function
    const handleSaveThumbnail = async () => {
        if (!newThumbnailFile) {
            Swal.fire({
                icon: 'error',
                title: 'Error',
                text: 'Please select an image file.',
            });
            return;
        }
        try {
            // Upload thumbnail to S3
            const imageUrl = await uploadThumbnail(newThumbnailFile);
            // Update thumbnail in the database
            await changeThumbnail(imageUrl, id);
            // Update the event data
            setEventData({ ...eventData, thumbnail: imageUrl });
            setIsEditingThumbnail(false);
            setNewThumbnailFile(null);
            Swal.fire({
                icon: 'success',
                title: 'Success',
                text: 'Thumbnail updated successfully!',
            });
        } catch (error) {
            console.error('Error updating thumbnail:', error);
            Swal.fire({
                icon: 'error',
                title: 'Error',
                text: error.message || 'Failed to update thumbnail.',
            });
        }
    };
    // Handle Save functions for each field
    const handleSaveTitle = async () => {
        if (!newTitle) {
            Swal.fire({
                icon: 'error',
                title: 'Error',
                text: ' Title Can\'t be empty',
            });
            return;
        }
        try {
            await changeTitle(newTitle, id);
            setEventData({ ...eventData, title: newTitle });
            setIsEditingTitle(false);
            Swal.fire({
                icon: 'success',
                title: 'Success',
                text: 'Event title updated successfully!',
            });
        } catch (error) {
            console.error('Error updating title:', error);
            Swal.fire({
                icon: 'error',
                title: 'Error',
                text: error.message || 'Failed to update event title.',
            });
        }
    };



    const handleEditTicket = (ticket) => {
        setCurrentTicket(ticket);
        setUpdatedTicketData(ticket);
        setIsEditingTickets(true);
    };

    const handleSaveTicket = async () => {
        try {
            await changeTicketVariation(currentTicket.id, updatedTicketData);
            Swal.fire({
                icon: 'success',
                title: 'Success',
                text: 'Ticket variation updated successfully!',
            });
            fetchTicketVariations(event.id); // Refresh variations after update
            setIsEditingTickets(false);
        } catch (error) {
            console.error('Error saving ticket variation:', error);
            Swal.fire({
                icon: 'error',
                title: 'Error',
                text: 'Failed to save ticket variation.',
            });
        }
    };



    const handleSaveGenre = async () => {
        if (!newGenre) {
            Swal.fire({
                icon: 'error',
                title: 'Error',
                text: ' Genre Can\'t be empty',
            });
            return;
        }
        try {
            await changeGenre(newGenre, id);
            setEventData({ ...eventData, genre: newGenre });
            setIsEditingGenre(false);
            Swal.fire({
                icon: 'success',
                title: 'Success',
                text: 'Event genre updated successfully!',
            });
        } catch (error) {
            console.error('Error updating genre:', error);
            Swal.fire({
                icon: 'error',
                title: 'Error',
                text: error.message || 'Failed to update event genre.',
            });
        }
    };



    const handleSaveDescription = async () => {

        try {
            await changeDescription(newDescription, id); // Function to be implemented later
            setEventData({ ...eventData, description: newDescription });
            setIsEditingDescription(false);
            Swal.fire({
                icon: 'success',
                title: 'Success',
                text: 'Event description updated successfully!',
            });
        } catch (error) {
            console.error('Error updating description:', error);
            Swal.fire({
                icon: 'error',
                title: 'Error',
                text: error.message || 'Failed to update event description.',
            });
        }
    };

    return (
        <div
            className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50"
            onClick={handleBackgroundClick}
        >
            <div className="bg-[#303261] p-8 rounded-2xl w-full max-w-4xl relative shadow-lg md:max-h-[80vh] max-h-[50vh] overflow-y-auto overflow-x-hidden">
                <div className="relative">
                    <img src={thumbnail} alt={title} className="w-full h-0 md:h-72 rounded-2xl object-cover mb-6" />
                    <button
                        onClick={() => setIsEditingThumbnail(true)}
                        className="absolute top-4 right-4 text-white hover:text-gray-300"
                    >
                        <FaPen />
                    </button>
                </div>

                {/* Edit Thumbnail Modal */}
                {isEditingThumbnail && (
                    <div
                        className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50"
                        onClick={(e) => {
                            if (e.target === e.currentTarget) {
                                setIsEditingThumbnail(false);
                                setNewThumbnailFile(null);
                            }
                        }}
                    >
                        <div className="bg-[#303261] p-8 rounded-lg w-full max-w-md">
                            <h2 className="text-2xl font-semibold mb-4 text-white">Edit Thumbnail</h2>
                            <input
                                type="file"
                                accept="image/*"
                                onChange={(e) => {
                                    const file = e.target.files[0];
                                    if (file) {
                                        if (file.type.startsWith('image/')) {
                                            setNewThumbnailFile(file);
                                        } else {
                                            Swal.fire({
                                                icon: 'error',
                                                title: 'Invalid File Type',
                                                text: 'Please select a valid image file.',
                                            });
                                            e.target.value = ''; // Reset the input value
                                        }
                                    }
                                }}
                                className="w-full px-4 py-3 border border-[#3c395d] rounded-xl text-xl bg-white text-black
                                    focus:ring-2 focus:ring-[#574bfc] focus:border-[#574bfc] focus:outline-none"
                            />
                            <div className="flex justify-center">
                                <button
                                    onClick={handleSaveThumbnail}
                                    className="bg-[#4954a5] text-white hover:bg-[#574bfc] text-2xl py-3 px-6 rounded-full mt-5"
                                >
                                    Save
                                </button>
                            </div>
                        </div>
                    </div>
                )}
                <div className="text-white">
                    <div className="flex mb-4">
                        <h2
                            className="md:text-5xl text-2xl font-semibold break-words min-w-0"
                            style={{ fontFamily: 'Poppins, sans-serif' }}
                        >
                            {title}
                        </h2>
                        <button
                            onClick={() => setIsEditingTitle(true)}
                            className="ml-4 text-white hover:text-gray-300 flex-shrink-0"
                        >
                            <FaPen />
                        </button>
                    </div>

                    {/* Edit Title Modal */}
                    {isEditingTitle && (
                        <div
                            className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50"
                            onClick={(e) => {
                                if (e.target === e.currentTarget) {
                                    setIsEditingTitle(false);
                                    setNewTitle('');
                                }
                            }}
                        >
                            <div className="bg-[#303261] p-8 rounded-lg w-full max-w-md">
                                <h2 className="text-2xl font-semibold mb-4 text-white">Edit Title</h2>
                                <input


                                    type="text"
                                    value={newTitle}
                                    onChange={(e) => setNewTitle(e.target.value)}
                                    className="w-full px-4 py-3 border border-[#3c395d] rounded-xl text-xl bg-white text-black placeholder-[#96a1b7]
                                        focus:ring-2 focus:ring-[#574bfc] focus:border-[#574bfc] focus:outline-none"
                                />
                                <div className="flex justify-center">
                                    <button
                                        onClick={handleSaveTitle}
                                        className="bg-[#4954a5] text-white hover:bg-[#574bfc] text-2xl py-3 px-6 rounded-full mt-5"
                                    >
                                        Save
                                    </button>
                                </div>
                            </div>
                        </div>
                    )}


                    <div className="mb-6">
                        <div className="flex mb-2">
                            <p className="md:text-lg text-base tracking-wide break-words  min-w-0">
                                <strong>Description:</strong> {description || '-'}
                            </p>
                            <button
                                onClick={() => {
                                    setIsEditingDescription(true);
                                    setNewDescription('');
                                }}
                                className="ml-2 text-white hover:text-gray-300 flex-shrink-0"
                            >
                                <FaPen />
                            </button>
                        </div>
                    </div>


                    {/* Edit Description Modal */}
                    {isEditingDescription && (
                        <div
                            className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50"
                            onClick={(e) => {
                                if (e.target === e.currentTarget) {
                                    setIsEditingDescription(false);
                                    setNewDescription('');
                                }
                            }}
                        >
                            <div className="bg-[#303261] p-8 rounded-lg w-full max-w-md">
                                <h2 className="text-2xl font-semibold mb-4 text-white">Edit Description</h2>
                                <textarea

                                    value={newDescription}
                                    onChange={(e) => setNewDescription(e.target.value)}
                                    className="w-full h-40 px-4 py-3 border border-[#3c395d] rounded-xl text-xl bg-white text-black placeholder-[#96a1b7]
                                        focus:ring-2 focus:ring-[#574bfc] focus:border-[#574bfc] focus:outline-none resize-none"
                                />
                                <div className="flex justify-center">
                                    <button
                                        onClick={handleSaveDescription}
                                        className="bg-[#4954a5] text-white hover:bg-[#574bfc] text-2xl py-3 px-6 rounded-full mt-5"
                                    >
                                        Save
                                    </button>
                                </div>
                            </div>
                        </div>
                    )}
                    {/* Event Date & Time */}
                    <div className="mb-6">
                        <div className="flex items-center">
                            <p className="md:text-lg text-base mb-2 tracking-wide">
                                <strong>Date:</strong> {formatDate(date)}
                            </p>

                        </div>



                        <div className="flex items-center">
                            <p className="md:text-lg text-base mb-2 tracking-wide">
                                <strong>Time:</strong> {time}
                            </p>

                        </div>


                    </div>
                    <div className="mb-5">
                        <h3 className="text-2xl font-semibold text-white mb-4">Tickets</h3>
                        <div className="space-y-4">
                            {ticketVariations.map((variation) => (
                                <div
                                    key={variation.id}
                                    className="border-2 p-4 rounded-lg text-white flex justify-between items-center"
                                >
                                    <div>
                                        <p className="md:text-lg text-base mb-2 tracking-wide">

                                            <strong>Type:</strong> {variation.type}
                                        </p>
                                        <p className="md:text-lg text-base mb-2 tracking-wide">

                                            <strong>Price:</strong> {variation.price} {currency}
                                        </p>
                                        <p className="md:text-lg text-base mb-2 tracking-wide">

                                            <strong>Quantity:</strong> {variation.quantity}
                                        </p>
                                    </div>
                                    <button onClick={() => handleEditTicket(variation)} className="ml-2 text-white hover:text-gray-300 mb-4">
                                        <FaPen />
                                    </button>
                                </div>
                            ))}
                        </div>
                    </div>

                    {/* Edit Ticket Modal */}
                    {isEditingTickets && (
                        <div
                            className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50"
                            onClick={(e) => {
                                if (e.target === e.currentTarget) {
                                    setIsEditingTickets(false);
                                    setCurrentTicket(null);
                                }
                            }}
                        >
                            <div className="bg-[#303261] p-8 rounded-lg w-full max-w-md">
                                <h2 className="text-2xl font-semibold mb-4 text-white">Edit Ticket</h2>

                                <input
                                    type="number"
                                    placeholder="Quantity"
                                    value={updatedTicketData.quantity || ''}
                                    min="1"
                                    onChange={(e) => {
                                        const inputValue = parseInt(e.target.value, 10) || '';
                                        if (inputValue === '' || inputValue > 0) {
                                            setUpdatedTicketData({ ...updatedTicketData, quantity: inputValue });
                                        }
                                    }}
                                    className="w-full px-4 py-3 border border-[#3c395d] rounded-xl text-xl bg-white text-black placeholder-[#96a1b7]
                focus:ring-2 focus:ring-[#574bfc] focus:border-[#574bfc] focus:outline-none"
                                />
                                <div className="flex justify-center space-x-4">
                                    <button
                                        onClick={() => {
                                            if (!updatedTicketData.type || updatedTicketData.quantity < 1) {
                                                Swal.fire({
                                                    icon: 'error',
                                                    title: 'Invalid Input',
                                                    text: 'Please enter a quantity greater than 0.',
                                                });
                                            } else {
                                                handleSaveTicket();
                                            }
                                        }}
                                        className="bg-[#4954a5] text-white hover:bg-[#574bfc] text-2xl py-3 px-6 rounded-full mt-5"
                                    >
                                        Save
                                    </button>
                                </div>
                            </div>

                        </div>
                    )}

                    {/* Venue and Pricing */}
                    <div className="grid grid-cols-2 gap-4">
                        <div>
                            <div>
                                <div className="flex mb-2">
                                    <p className="md:text-lg text-base tracking-wide break-words min-w-0">
                                        <strong>Venue:</strong> {venue}
                                    </p>

                                </div>

                            </div>



                        </div>

                    </div>

                    {/* DJ and Tickets Info */}
                    <div className="grid grid-cols-2 gap-4 mt-4">
                        <div>
                            <div className="flex">
                                <p className="md:text-lg text-base tracking-wide break-words min-w-0">
                                    <strong>DJ Name/Presenter:</strong> {djname}
                                </p>

                            </div>


                        </div>
                        <div>


                        </div>
                        <div className="flex items-center">
                            <button
                                className="md:text-lg text-base tracking-wide underline text-[#574bfc] hover:text-[#cc15ec]"
                                onClick={openAttendeesModal}
                            >
                                <strong>Attending</strong>
                            </button>
                            <p className="md:text-lg text-base tracking-wide ml-2">
                                <strong>:</strong> {attending}
                            </p>
                        </div>

                    </div>

                    <div className="flex items-center mt-6">
                        <button
                            className="md:text-lg text-base tracking-wide underline text-[#574bfc] hover:text-[#cc15ec]"
                            onClick={openRevenueModal}
                        >
                            <strong>View Ticket Revenue</strong>
                        </button>
                    </div>
                    <div className="flex items-center mt-6">
                        <p className="md:text-lg text-base">
                            <strong>City:</strong> {city}
                        </p>

                    </div>


                    <div className="flex items-center mt-2">
                        <p className="md:text-lg text-base">
                            <strong>State:</strong> {state || '-'}
                        </p>

                    </div>



                    {/* Show Country */}
                    <div className="flex items-center mt-2">
                        <p className="md:text-lg text-base">
                            <strong>Country:</strong> {country}
                        </p>

                    </div>



                    <div className="flex items-center mt-2">
                        <a
                            href={location}
                            className="text-[#574bfc] md:text-lg text-base underline hover:text-[#cc15ec]"
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            <strong>Show Location</strong>
                        </a>

                    </div>


                    {/* Genres */}
                    <div className="mt-8">
                        <div className="flex items-center">
                            <h3 className="md:text-2xl text-lg font-semibold tracking-wider mb-4" style={{ fontFamily: 'Poppins, sans-serif' }}>
                                Genres
                            </h3>
                            <button onClick={() => setIsEditingGenre(true)} className="ml-2 text-white hover:text-gray-300 mb-4">
                                <FaPen />
                            </button>
                        </div>

                        {/* Edit Genre Modal */}
                        {isEditingGenre && (
                            <div
                                className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50"
                                onClick={(e) => {
                                    if (e.target === e.currentTarget) {
                                        setIsEditingGenre(false);
                                        setNewGenre('');
                                    }
                                }}
                            >
                                <div className="bg-[#303261] p-8 rounded-lg w-full max-w-md">
                                    <h2 className="text-2xl font-semibold mb-4 text-white">Edit Genres</h2>
                                    <input
                                        type="text"
                                        value={newGenre}
                                        onChange={(e) => setNewGenre(e.target.value)}
                                        placeholder="If multiple, separate by commas"
                                        className="w-full px-4 py-3 border border-[#3c395d] rounded-xl text-xl bg-white text-black placeholder-[#96a1b7]
                                            focus:ring-2 focus:ring-[#574bfc] focus:border-[#574bfc] focus:outline-none"
                                    />
                                    <div className="flex justify-center">
                                        <button
                                            onClick={handleSaveGenre}
                                            className="bg-[#4954a5] text-white hover:bg-[#574bfc] text-2xl py-3 px-6 rounded-full mt-5"
                                        >
                                            Save
                                        </button>
                                    </div>
                                </div>
                            </div>
                        )}

                        <div className="flex flex-wrap gap-2">
                            {genre.split(',').map((tag, index) => (
                                <span
                                    key={index}
                                    className="px-3 py-1 bg-[#574bfc] text-sm font-medium rounded-full"
                                    style={{ fontFamily: 'Poppins, sans-serif', letterSpacing: '0.5px' }}
                                >
                                    {tag.trim()}
                                </span>
                            ))}
                        </div>
                        <div className="flex items-center mt-4">
                        </div>
                    </div>
                </div>
                {/* Attendees Modal */}
                {isAttendeesModalOpen && (
                    <AttendeesModal eventId={id} eventTitle={title} onClose={closeAttendeesModal} />
                )}
                {/* Ticket Revenue Modal */}
                {isRevenueModalOpen && (
                    <TicketRevenueModal
                        eventId={event.id}
                        eventTitle={event.title}
                        onClose={closeRevenueModal}
                    />
                )}
            </div>
        </div>
    );
};

export default EventModal;
