// components/EventAdminModal.js

import React, { useState, useEffect } from 'react';
import AttendeesModal from '../components/attendeesModal'; // Import the AttendeesModal component
import TicketRevenueModal from '../components/ticketRevenueModal';
import Swal from 'sweetalert2';
import {
    getTicketVariations, approvePost, rejectPost // New API function to fetch ticket variations
} from '../lib/adminapi'; // Adjust the import path as necessary

const EventAdminPendingModal = ({ event, onClose }) => {
    const [eventData, setEventData] = useState(event);
    const [isAttendeesModalOpen, setIsAttendeesModalOpen] = useState(false);
    const [isRevenueModalOpen, setRevenueModalOpen] = useState(false);
    const [ticketVariations, setTicketVariations] = useState([]); // State for ticket variations


    const ApprovePostHandler = async () => {
        const confirmResult = await Swal.fire({
            title: 'Are you sure?',
            text: 'Do you want to approve this event?',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Yes, approve it!',
            cancelButtonText: 'No, cancel',
        });

        if (confirmResult.isConfirmed) {
            try {
                const response = await approvePost(event.id); // Call the API to approve the post

                if (response.success) {
                    Swal.fire({
                        title: 'Approved!',
                        text: 'The event has been approved successfully.',
                        icon: 'success',
                        confirmButtonColor: '#28a745',
                    });
                    // Optionally, close the modal after approval
                    onClose();
                } else {
                    Swal.fire({
                        title: 'Error!',
                        text: response.error || 'Failed to approve the event.',
                        icon: 'error',
                        confirmButtonColor: '#d33',
                    });
                }
            } catch (error) {
                console.error('Error approving post:', error);
                Swal.fire({
                    title: 'Error!',
                    text: 'An unexpected error occurred while approving the event.',
                    icon: 'error',
                    confirmButtonColor: '#d33',
                });
            }
        }
    };

    const rejectPostHandler = async () => {
        const confirmResult = await Swal.fire({
            title: 'Are you sure?',
            text: 'Do you want to reject this event?',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Yes, reject it!',
            cancelButtonText: 'No, cancel',
        });

        if (confirmResult.isConfirmed) {
            try {
                const response = await rejectPost(event.id); // Call the API to approve the post

                if (response.success) {
                    Swal.fire({
                        title: 'Rejected!',
                        text: 'The event has been rejected successfully.',
                        icon: 'success',
                        confirmButtonColor: '#28a745',
                    });
                    // Optionally, close the modal after approval
                    onClose();
                } else {
                    Swal.fire({
                        title: 'Error!',
                        text: response.error || 'Failed to reject the event.',
                        icon: 'error',
                        confirmButtonColor: '#d33',
                    });
                }
            } catch (error) {
                console.error('Error rejecting post:', error);
                Swal.fire({
                    title: 'Error!',
                    text: 'An unexpected error occurred while rejecting the event.',
                    icon: 'error',
                    confirmButtonColor: '#d33',
                });
            }
        }
    };
    useEffect(() => {
        if (event) {
            setEventData(event);
            fetchTicketVariations(event.id);
        }
    }, [event]);


    if (!eventData) return null;

    const {
        title,
        thumbnail,
        venue,
        date,
        time,
        genre,
        currency,
        djname,
        attending,
        location,
        city,
        state,
        country,
        description,
        id, // Assuming each event has a unique id
    } = eventData;

    const formatDate = (dateString) => {
        const date = new Date(dateString);
        if (isNaN(date.getTime())) return '';

        const day = date.getDate();
        const month = date.toLocaleString('default', { month: 'long' }); // "December"
        const year = date.getFullYear();

        return `${day} ${month}, ${year}`; // "11 December, 2024"
    };

    const handleBackgroundClick = (e) => {
        if (e.target === e.currentTarget) {
            onClose();
        }
    };

    const openAttendeesModal = () => {
        setIsAttendeesModalOpen(true);
    };

    const closeAttendeesModal = () => {
        setIsAttendeesModalOpen(false);
    };





    const fetchTicketVariations = async (eventId) => {
        try {
            const variations = await getTicketVariations(eventId);
            setTicketVariations(variations);
        } catch (error) {
            console.error('Error fetching ticket variations:', error);
            Swal.fire({
                icon: 'error',
                title: 'Error',
                text: 'Failed to fetch ticket variations.',
            });
        }
    };





    const openRevenueModal = () => setRevenueModalOpen(true);
    const closeRevenueModal = () => setRevenueModalOpen(false);


    return (
        <div
            className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50"
            onClick={handleBackgroundClick}
        >
            <div className="bg-[#303261] p-8 rounded-2xl w-full max-w-4xl relative shadow-lg md:max-h-[80vh] max-h-[50vh] overflow-y-auto overflow-x-hidden">
                <div className="relative">
                    <img src={thumbnail} alt={title} className="w-full h-0 md:h-72 rounded-2xl object-cover mb-6" />

                </div>


                <div className="text-white">
                    <div className="flex mb-4">
                        <h2
                            className="md:text-5xl text-2xl font-semibold break-words min-w-0"
                            style={{ fontFamily: 'Poppins, sans-serif' }}
                        >
                            {title}
                        </h2>

                    </div>



                    <div className="mb-6">
                        <div className="flex mb-2">
                            <p className="md:text-lg text-base tracking-wide break-words  min-w-0">
                                <strong>Description:</strong> {description || '-'}
                            </p>

                        </div>
                    </div>



                    {/* Event Date & Time */}
                    <div className="mb-6">
                        <div className="flex items-center">
                            <p className="md:text-lg text-base mb-2 tracking-wide">
                                <strong>Date:</strong> {formatDate(date)}
                            </p>

                        </div>



                        <div className="flex items-center">
                            <p className="md:text-lg text-base mb-2 tracking-wide">
                                <strong>Time:</strong> {time}
                            </p>

                        </div>


                    </div>
                    <div className="mb-5">
                        <h3 className="text-2xl font-semibold text-white mb-4">Ticket Variations</h3>
                        <div className="space-y-4">
                            {ticketVariations.map((variation) => (
                                <div
                                    key={variation.id}
                                    className="border-2 p-4 rounded-lg text-white flex justify-between items-center"
                                >
                                    <div>
                                        <p className="md:text-lg text-base mb-2 tracking-wide">

                                            <strong>Type:</strong> {variation.type}
                                        </p>
                                        <p className="md:text-lg text-base mb-2 tracking-wide">

                                            <strong>Price:</strong> {variation.price} {currency}
                                        </p>
                                        <p className="md:text-lg text-base mb-2 tracking-wide">

                                            <strong>Quantity:</strong> {variation.quantity}
                                        </p>
                                    </div>

                                </div>
                            ))}
                        </div>
                    </div>



                    {/* Venue and Pricing */}
                    <div className="grid grid-cols-2 gap-4">
                        <div>
                            <div>
                                <div className="flex mb-2">
                                    <p className="md:text-lg text-base tracking-wide break-words min-w-0">
                                        <strong>Venue:</strong> {venue}
                                    </p>

                                </div>
                                {/* ... */}
                            </div>



                        </div>
                    </div>

                    {/* DJ and Tickets Info */}
                    <div className="grid grid-cols-2 gap-4 mt-4">
                        <div>
                            <div className="flex">
                                <p className="md:text-lg text-base tracking-wide break-words min-w-0">
                                    <strong>DJ Name/Presenter:</strong> {djname}
                                </p>

                            </div>


                        </div>
                        <div>


                        </div>
                        <div className="flex items-center">
                            <button
                                className="md:text-lg text-base tracking-wide underline text-[#574bfc] hover:text-[#cc15ec]"
                                onClick={openAttendeesModal}
                            >
                                <strong>Attending</strong>
                            </button>
                            <p className="md:text-lg text-base tracking-wide ml-2">
                                <strong>:</strong> {attending}
                            </p>
                        </div>
                    </div>
                    <div className="flex items-center mt-6">
                        <button
                            className="md:text-lg text-base tracking-wide underline text-[#574bfc] hover:text-[#cc15ec]"
                            onClick={openRevenueModal}
                        >
                            <strong>View Ticket Revenue</strong>
                        </button>
                    </div>

                    <div className="flex items-center mt-6">
                        <p className="md:text-lg text-base">
                            <strong>City:</strong> {city}
                        </p>

                    </div>


                    <div className="flex items-center mt-2">
                        <p className="md:text-lg text-base">
                            <strong>State:</strong> {state || '-'}
                        </p>

                    </div>



                    {/* Show Country */}
                    <div className="flex items-center mt-2">
                        <p className="md:text-lg text-base">
                            <strong>Country:</strong> {country}
                        </p>

                    </div>



                    <div className="flex items-center mt-2">
                        <a
                            href={location}
                            className="text-[#574bfc] md:text-lg text-base underline hover:text-[#cc15ec]"
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            <strong>Show Location</strong>
                        </a>

                    </div>



                    {/* Genres */}
                    <div className="mt-8">
                        <div className="flex items-center">
                            <h3 className="md:text-2xl text-lg font-semibold tracking-wider mb-4" style={{ fontFamily: 'Poppins, sans-serif' }}>
                                Genres
                            </h3>
                        </div>



                        <div className="flex flex-wrap gap-2">
                            {genre.split(',').map((tag, index) => (
                                <span
                                    key={index}
                                    className="px-3 py-1 bg-[#574bfc] text-sm font-medium rounded-full"
                                    style={{ fontFamily: 'Poppins, sans-serif', letterSpacing: '0.5px' }}
                                >
                                    {tag.trim()}
                                </span>
                            ))}
                        </div>
                        <div className="flex items-center mt-4">

                            <button
                                onClick={ApprovePostHandler}
                                className="text-[#574bfc] md:text-lg text-base underline hover:text-[#cc15ec]"
                            >
                                Approve Event
                            </button>
                        </div>
                        <div className="flex items-center mt-4">

                            <button
                                onClick={rejectPostHandler}
                                className="text-[#574bfc] md:text-lg text-base underline hover:text-[#cc15ec]"
                            >
                                Reject Event
                            </button>
                        </div>
                    </div>
                </div>

                {/* Attendees Modal */}
                {isAttendeesModalOpen && (
                    <AttendeesModal eventId={id} eventTitle={title} onClose={closeAttendeesModal} />
                )}
                {isRevenueModalOpen && (
                    <TicketRevenueModal
                        eventId={event.id}
                        eventTitle={event.title}
                        onClose={closeRevenueModal}
                    />
                )}
            </div>
        </div>
    );
};

export default EventAdminPendingModal;
