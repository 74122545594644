// components/TicketRevenueModal.js

import React, { useEffect, useState } from 'react';
import { getTicketSummary } from '../lib/adminapi'; // Import the new getTicketSummary function
import * as XLSX from 'xlsx'; // Import XLSX library

const TicketRevenueModal = ({ eventId, eventTitle, onClose }) => {
    const [ticketSummary, setTicketSummary] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        const fetchTicketSummary = async () => {
            try {
                const summaryData = await getTicketSummary(eventId);
                setTicketSummary(summaryData);
            } catch (err) {
                console.error("Error fetching ticket summary:", err);
                setError("Failed to load ticket summary.");
            } finally {
                setLoading(false);
            }
        };

        fetchTicketSummary();
    }, [eventId]);

    const handleBackgroundClick = (e) => {
        if (e.target === e.currentTarget) {
            onClose();
        }
    };

    const handleExtract = () => {
        if (ticketSummary.length === 0) return;

        // Prepare the data for the Excel sheet
        const data = ticketSummary.map(({ type, price, ticketsSold, totalRevenue }) => ({
            'Ticket Type': type,
            'Price': price,
            'Tickets Sold': ticketsSold,
            'Total Revenue': totalRevenue.toFixed(3),
        }));

        // Calculate overall totals
        const totalTickets = ticketSummary.reduce((acc, curr) => acc + curr.ticketsSold, 0);
        const totalRevenue = ticketSummary.reduce((acc, curr) => acc + curr.totalRevenue, 0);

        // Add totals row
        data.push({
            'Ticket Type': 'Total',
            'Price': '',
            'Tickets Sold': totalTickets,
            'Total Revenue': totalRevenue.toFixed(3),
        });

        // Create a worksheet and a workbook
        const worksheet = XLSX.utils.json_to_sheet(data);
        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, 'Ticket Summary');

        // Generate the Excel file and trigger a download
        XLSX.writeFile(workbook, `Ticket_Summary_Event_${eventTitle}.xlsx`);
    };

    return (
        <div
            className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50"
            onClick={handleBackgroundClick}
        >
            <div className="bg-[#303261] p-6 rounded-xl w-full max-w-md relative shadow-lg">

                <h2 className="text-2xl font-semibold text-white mb-4">Ticket Revenue Summary</h2>
                {loading ? (
                    <p className="text-white">Loading...</p>
                ) : error ? (
                    <p className="text-red-500">{error}</p>
                ) : ticketSummary.length > 0 ? (
                    <div>
                        <div className="md:max-h-80 max-h-40 overflow-y-auto mb-4">
                            <table className="w-full text-left">
                                <thead>
                                    <tr>
                                        <th className="text-white px-2 py-1">Ticket Type</th>
                                        <th className="text-white px-2 py-1">Price</th>
                                        <th className="text-white px-2 py-1">Tickets Sold</th>
                                        <th className="text-white px-2 py-1">Total Revenue</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {ticketSummary.map((summary, index) => (
                                        <tr key={index} className="border-t border-gray-700">
                                            <td className="text-white px-2 py-1">{summary.type}</td>
                                            <td className="text-gray-300 px-2 py-1">{summary.price.toFixed(3)}</td>
                                            <td className="text-gray-300 px-2 py-1">{summary.ticketsSold}</td>
                                            <td className="text-gray-300 px-2 py-1">{summary.totalRevenue.toFixed(3)}</td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                        <button
                            className="bg-[#4954a5] hover:bg-[#574bfc] text-white font-bold py-2 px-4 rounded w-full"
                            onClick={handleExtract}
                        >
                            Extract to Excel
                        </button>
                    </div>

                ) : (
                    <p className="text-white">No tickets sold.</p>
                )}
            </div>
        </div>
    );
};

export default TicketRevenueModal;
