// components/EventAdminModal.js

import React, { useState, useEffect } from 'react';
import AttendeesModal from '../components/attendeesModal'; // Import the AttendeesModal component
import TicketRevenueModal from '../components/ticketRevenueModal';
import { FaPen, FaCalendarAlt, FaClock } from 'react-icons/fa';
import Swal from 'sweetalert2';
import * as XLSX from 'xlsx'; // Import XLSX library

import {
    changeTitle,
    changeVenue,
    getTicketVariations, // New API function to fetch ticket variations
    changeTicketVariation,
    changeDjName,
    changeDate,
    changeTime,
    changeGenre,
    changeCity,
    changeLocation,
    changeState,
    changeCountry,
    changeDescription,
    changeThumbnail, // Import the changeThumbnail function
    getPresignedUrl,
    uploadFileToS3,
    deletePost,
    refundUser,
    getOrderDataForEvent
} from '../lib/adminapi'; // Adjust the import path as necessary

const EventAdminModal = ({ event, onClose }) => {
    const [eventData, setEventData] = useState(event);
    const [isAttendeesModalOpen, setIsAttendeesModalOpen] = useState(false);
    const [isEditingTitle, setIsEditingTitle] = useState(false);
    const [newTitle, setNewTitle] = useState('');

    // State variables for editing fields
    const [isEditingVenue, setIsEditingVenue] = useState(false);
    const [newVenue, setNewVenue] = useState('');

    const [isRevenueModalOpen, setRevenueModalOpen] = useState(false);

    const [ticketVariations, setTicketVariations] = useState([]); // State for ticket variations
    const [updatedTicketData, setUpdatedTicketData] = useState({}); // Holds updates for the current ticket
    const [isEditingTickets, setIsEditingTickets] = useState(false);
    const [currentTicket, setCurrentTicket] = useState(null); // Holds the current ticket being edited

    const [isEditingDjName, setIsEditingDjName] = useState(false);
    const [newDjName, setNewDjName] = useState('');

    const [isEditingDate, setIsEditingDate] = useState(false);
    const [newDate, setNewDate] = useState('');

    const [isEditingTime, setIsEditingTime] = useState(false);
    const [newStartTime, setNewStartTime] = useState('');
    const [newEndTime, setNewEndTime] = useState('');

    const [isEditingGenre, setIsEditingGenre] = useState(false);
    const [newGenre, setNewGenre] = useState('');

    const [isEditingCity, setIsEditingCity] = useState(false);
    const [newCity, setNewCity] = useState('');

    const [isEditingLocation, setIsEditingLocation] = useState(false);
    const [newLocation, setNewLocation] = useState('');

    const [isEditingState, setIsEditingState] = useState(false);
    const [newState, setNewState] = useState('');

    const [isEditingCountry, setIsEditingCountry] = useState(false);
    const [newCountry, setNewCountry] = useState('');

    const [isEditingDescription, setIsEditingDescription] = useState(false);
    const [newDescription, setNewDescription] = useState('');

    const [isEditingThumbnail, setIsEditingThumbnail] = useState(false); // Add state for editing thumbnail
    const [newThumbnailFile, setNewThumbnailFile] = useState(null);

    const [isRefundModalOpen, setIsRefundModalOpen] = useState(false);
    const [transactionReference, setTransactionReference] = useState('');

    const openRefundModal = () => setIsRefundModalOpen(true);
    const closeRefundModal = () => {
        setIsRefundModalOpen(false);
        setTransactionReference('');
    };

    const handleRefund = async () => {
        if (!transactionReference.trim()) {
            Swal.fire({
                icon: 'error',
                title: 'Error',
                text: 'Transaction Reference cannot be empty.',
            });
            return;
        }

        try {
            await refundUser(transactionReference, event.id);
            Swal.fire({
                icon: 'success',
                title: 'Success',
                text: 'User Ticket(s) has been deleted successfully.',
            });
            closeRefundModal();
            // Optionally, refresh event data or perform other actions
            fetchTicketVariations(event.id);
            // Refresh attending count if necessary
            // fetchEventData(eventData.id); // Implement this if needed
        } catch (error) {
            console.error('Refund Error:', error);
            Swal.fire({
                icon: 'error',
                title: 'Error',
                text: error.message || 'Failed to delete the ticket.',
            });
        }
    };
    useEffect(() => {
        if (event) {
            setEventData(event);
            fetchTicketVariations(event.id);
        }
    }, [event]);

    useEffect(() => {
        if (isEditingTickets) {
            setUpdatedTicketData({ type: '', price: '', quantity: '' });
        }
    }, [isEditingTickets]);

    if (!eventData) return null;

    const {
        title,
        thumbnail,
        venue,
        date,
        time,
        genre,
        currency,
        djname,
        attending,
        location,
        city,
        state,
        country,
        description,
        id, // Assuming each event has a unique id
    } = eventData;

    const formatDate = (dateString) => {
        const date = new Date(dateString);
        if (isNaN(date.getTime())) return '';

        const day = date.getDate();
        const month = date.toLocaleString('default', { month: 'long' }); // "December"
        const year = date.getFullYear();

        return `${day} ${month}, ${year}`; // "11 December, 2024"
    };

    const handleBackgroundClick = (e) => {
        if (e.target === e.currentTarget) {
            onClose();
        }
    };

    const openAttendeesModal = () => {
        setIsAttendeesModalOpen(true);
    };

    const closeAttendeesModal = () => {
        setIsAttendeesModalOpen(false);
    };

    // Utility function to convert 24-hour time to 12-hour format with AM/PM
    function convertTo12Hour(time24) {
        const [hour, minute] = time24.split(':').map(Number);
        const ampm = hour >= 12 ? 'pm' : 'am';
        const hour12 = hour % 12 === 0 ? 12 : hour % 12;
        return `${hour12}:${minute.toString().padStart(2, '0')}${ampm}`;
    }

    async function uploadThumbnail(thumbnailFile) {
        try {
            const { url, fields, key } = await getPresignedUrl(
                thumbnailFile.name,
                thumbnailFile.type
            );

            // Prepare the form data for S3 upload
            const formDataImage = new FormData();
            Object.entries(fields).forEach(([fieldKey, fieldValue]) => {
                formDataImage.append(fieldKey, fieldValue);
            });
            formDataImage.append('file', thumbnailFile);

            // Upload the file directly to S3
            await uploadFileToS3(url, formDataImage);

            // Construct the file URL
            const imageUrl = `${url}/${key}`;
            return imageUrl;
        } catch (error) {
            console.error('Error uploading thumbnail:', error);
            throw error;
        }
    }

    const fetchTicketVariations = async (eventId) => {
        try {
            const variations = await getTicketVariations(eventId);
            setTicketVariations(variations);
        } catch (error) {
            console.error('Error fetching ticket variations:', error);
            Swal.fire({
                icon: 'error',
                title: 'Error',
                text: 'Failed to fetch ticket variations.',
            });
        }
    };

    // Handle Save Thumbnail function
    const handleSaveThumbnail = async () => {
        if (!newThumbnailFile) {
            Swal.fire({
                icon: 'error',
                title: 'Error',
                text: 'Please select an image file.',
            });
            return;
        }
        try {
            // Upload thumbnail to S3
            const imageUrl = await uploadThumbnail(newThumbnailFile);
            // Update thumbnail in the database
            await changeThumbnail(imageUrl, id);
            // Update the event data
            setEventData({ ...eventData, thumbnail: imageUrl });
            setIsEditingThumbnail(false);
            setNewThumbnailFile(null);
            Swal.fire({
                icon: 'success',
                title: 'Success',
                text: 'Thumbnail updated successfully!',
            });
        } catch (error) {
            console.error('Error updating thumbnail:', error);
            Swal.fire({
                icon: 'error',
                title: 'Error',
                text: error.message || 'Failed to update thumbnail.',
            });
        }
    };
    // Handle Save functions for each field
    const handleSaveTitle = async () => {
        if (!newTitle) {
            Swal.fire({
                icon: 'error',
                title: 'Error',
                text: ' Title Can\'t be empty',
            });
            return;
        }
        try {
            await changeTitle(newTitle, id);
            setEventData({ ...eventData, title: newTitle });
            setIsEditingTitle(false);
            Swal.fire({
                icon: 'success',
                title: 'Success',
                text: 'Event title updated successfully!',
            });
        } catch (error) {
            console.error('Error updating title:', error);
            Swal.fire({
                icon: 'error',
                title: 'Error',
                text: error.message || 'Failed to update event title.',
            });
        }
    };

    const handleSaveVenue = async () => {
        if (!newVenue) {
            Swal.fire({
                icon: 'error',
                title: 'Error',
                text: ' Venue Can\'t be empty',
            });
            return;
        }
        try {
            await changeVenue(newVenue, id);
            setEventData({ ...eventData, venue: newVenue });
            setIsEditingVenue(false);
            Swal.fire({
                icon: 'success',
                title: 'Success',
                text: 'Event venue updated successfully!',
            });
        } catch (error) {
            console.error('Error updating venue:', error);
            Swal.fire({
                icon: 'error',
                title: 'Error',
                text: error.message || 'Failed to update event venue.',
            });
        }
    };
    const openRevenueModal = () => setRevenueModalOpen(true);
    const closeRevenueModal = () => setRevenueModalOpen(false);
    const handleEditTicket = (ticket) => {
        setCurrentTicket(ticket);
        setUpdatedTicketData(ticket);
        setIsEditingTickets(true);
    };

    const handleSaveTicket = async () => {
        try {
            await changeTicketVariation(currentTicket.id, updatedTicketData);
            Swal.fire({
                icon: 'success',
                title: 'Success',
                text: 'Ticket variation updated successfully!',
            });
            fetchTicketVariations(event.id); // Refresh variations after update
            setIsEditingTickets(false);
        } catch (error) {
            console.error('Error saving ticket variation:', error);
            Swal.fire({
                icon: 'error',
                title: 'Error',
                text: 'Failed to save ticket variation.',
            });
        }
    };
    const deletePostHandler = async () => {
        const result = await Swal.fire({
            title: 'Are you sure?',
            text: 'This action will permanently delete the event.',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#d33', // Red color for delete action
            cancelButtonColor: 'green',
            confirmButtonText: 'Yes, delete it!',
        });

        if (result.isConfirmed) {
            try {
                await deletePost(id);
                Swal.fire({
                    icon: 'success',
                    title: 'Deleted!',
                    text: 'The event has been deleted.',
                });
                // Close the modal after deletion
                onClose();
                // Optionally, refresh the event list or handle state updates
            } catch (error) {
                console.error('Error deleting post:', error);
                Swal.fire({
                    icon: 'error',
                    title: 'Error',
                    text: error.message || 'Failed to delete the event.',
                });
            }
        }
    };

    const downloadOrdersHandler = async () => {
        try {
            // 1. Fetch the orders for this event
            const orders = await getOrderDataForEvent(event.id);
            if (!orders || orders.length === 0) {
                Swal.fire({
                    icon: 'info',
                    title: 'No Orders',
                    text: 'No orders found for this event.',
                });
                return;
            }

            // 2. Convert to a JSON format for xlsx
            //    For example, if each order is {transactionReference, userEmail, quantity, ...}
            //    Adjust fields to match your actual data
            const data = orders.map((ord) => ({
                'Transaction Ref': ord.transactionReference,

            }));

            // 3. Convert to a worksheet and workbook
            const worksheet = XLSX.utils.json_to_sheet(data);
            const workbook = XLSX.utils.book_new();
            XLSX.utils.book_append_sheet(workbook, worksheet, 'Orders');

            // 4. Generate the Excel file and trigger download
            XLSX.writeFile(workbook, `Orders_${event.title}.xlsx`);

            Swal.fire({
                icon: 'success',
                title: 'File Downloaded',
                text: 'Orders data downloaded successfully!',
            });
        } catch (error) {
            console.error('Error exporting orders:', error);
            Swal.fire({
                icon: 'error',
                title: 'Error',
                text: 'Failed to export orders.',
            });
        }
    };

    const handleSaveDjName = async () => {
        if (!newDjName) {
            Swal.fire({
                icon: 'error',
                title: 'Error',
                text: ' Dj Name/Presenter Can\'t be empty',
            });
            return;
        }
        try {
            await changeDjName(newDjName, id);
            setEventData({ ...eventData, djname: newDjName });
            setIsEditingDjName(false);
            Swal.fire({
                icon: 'success',
                title: 'Success',
                text: 'Event DJ Name updated successfully!',
            });
        } catch (error) {
            console.error('Error updating DJ Name:', error);
            Swal.fire({
                icon: 'error',
                title: 'Error',
                text: error.message || 'Failed to update event DJ Name.',
            });
        }
    };

    const handleSaveDate = async () => {
        if (!newDate) {
            Swal.fire({
                icon: 'error',
                title: 'Error',
                text: ' Date Can\'t be empty',
            });
            return;
        }
        try {
            await changeDate(newDate, id);
            setEventData({ ...eventData, date: newDate });
            setIsEditingDate(false);
            Swal.fire({
                icon: 'success',
                title: 'Success',
                text: 'Event date updated successfully!',
            });
        } catch (error) {
            console.error('Error updating date:', error);
            Swal.fire({
                icon: 'error',
                title: 'Error',
                text: error.message || 'Failed to update event date.',
            });
        }
    };

    const handleSaveTime = async () => {
        if (!newStartTime || !newEndTime) {
            Swal.fire({
                icon: 'error',
                title: 'Error',
                text: ' Time Can\'t be empty',
            });
            return;
        }
        try {
            const startTime12 = convertTo12Hour(newStartTime);
            const endTime12 = convertTo12Hour(newEndTime);
            const updatedTime = `${startTime12}-${endTime12}`;
            await changeTime(updatedTime, id);
            setEventData({ ...eventData, time: updatedTime });
            setIsEditingTime(false);
            Swal.fire({
                icon: 'success',
                title: 'Success',
                text: 'Event time updated successfully!',
            });
        } catch (error) {
            console.error('Error updating time:', error);
            Swal.fire({
                icon: 'error',
                title: 'Error',
                text: error.message || 'Failed to update event time.',
            });
        }
    };

    const handleSaveGenre = async () => {
        if (!newGenre) {
            Swal.fire({
                icon: 'error',
                title: 'Error',
                text: ' Genre Can\'t be empty',
            });
            return;
        }
        try {
            await changeGenre(newGenre, id);
            setEventData({ ...eventData, genre: newGenre });
            setIsEditingGenre(false);
            Swal.fire({
                icon: 'success',
                title: 'Success',
                text: 'Event genre updated successfully!',
            });
        } catch (error) {
            console.error('Error updating genre:', error);
            Swal.fire({
                icon: 'error',
                title: 'Error',
                text: error.message || 'Failed to update event genre.',
            });
        }
    };

    const handleSaveCity = async () => {
        if (!newCity) {
            Swal.fire({
                icon: 'error',
                title: 'Error',
                text: ' City Can\'t be empty',
            });
            return;
        }
        try {
            await changeCity(newCity, id);
            setEventData({ ...eventData, city: newCity });
            setIsEditingCity(false);
            Swal.fire({
                icon: 'success',
                title: 'Success',
                text: 'Event city updated successfully!',
            });
        } catch (error) {
            console.error('Error updating city:', error);
            Swal.fire({
                icon: 'error',
                title: 'Error',
                text: error.message || 'Failed to update event city.',
            });
        }
    };

    const handleSaveLocation = async () => {
        if (!newLocation) {
            Swal.fire({
                icon: 'error',
                title: 'Error',
                text: ' Location Can\'t be empty',
            });
            return;
        }
        try {
            await changeLocation(newLocation, id);
            setEventData({ ...eventData, location: newLocation });
            setIsEditingLocation(false);
            Swal.fire({
                icon: 'success',
                title: 'Success',
                text: 'Event location updated successfully!',
            });
        } catch (error) {
            console.error('Error updating location:', error);
            Swal.fire({
                icon: 'error',
                title: 'Error',
                text: error.message || 'Failed to update event location.',
            });
        }
    };

    const handleSaveState = async () => {
        try {
            await changeState(newState, id); // Function to be implemented later
            setEventData({ ...eventData, state: newState });
            setIsEditingState(false);
            Swal.fire({
                icon: 'success',
                title: 'Success',
                text: 'Event state updated successfully!',
            });
        } catch (error) {
            console.error('Error updating state:', error);
            Swal.fire({
                icon: 'error',
                title: 'Error',
                text: error.message || 'Failed to update event state.',
            });
        }
    };

    const handleSaveCountry = async () => {
        if (!newCountry) {
            Swal.fire({
                icon: 'error',
                title: 'Error',
                text: ' Country Can\'t be empty',
            });
            return;
        }
        try {
            await changeCountry(newCountry, id); // Function to be implemented later
            setEventData({ ...eventData, country: newCountry });
            setIsEditingCountry(false);
            Swal.fire({
                icon: 'success',
                title: 'Success',
                text: 'Event country updated successfully!',
            });
        } catch (error) {
            console.error('Error updating country:', error);
            Swal.fire({
                icon: 'error',
                title: 'Error',
                text: error.message || 'Failed to update event country.',
            });
        }
    };

    const handleSaveDescription = async () => {

        try {
            await changeDescription(newDescription, id); // Function to be implemented later
            setEventData({ ...eventData, description: newDescription });
            setIsEditingDescription(false);
            Swal.fire({
                icon: 'success',
                title: 'Success',
                text: 'Event description updated successfully!',
            });
        } catch (error) {
            console.error('Error updating description:', error);
            Swal.fire({
                icon: 'error',
                title: 'Error',
                text: error.message || 'Failed to update event description.',
            });
        }
    };

    return (
        <div
            className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50"
            onClick={handleBackgroundClick}
        >
            <div className="bg-[#303261] p-8 rounded-2xl w-full max-w-4xl relative shadow-lg md:max-h-[80vh] max-h-[50vh] overflow-y-auto overflow-x-hidden">
                <div className="relative">
                    <img src={thumbnail} alt={title} className="w-full h-0 md:h-72 rounded-2xl object-cover mb-6" />
                    <button
                        onClick={() => setIsEditingThumbnail(true)}
                        className="absolute top-4 right-4 text-white hover:text-gray-300"
                    >
                        <FaPen />
                    </button>
                </div>

                {/* Edit Thumbnail Modal */}
                {isEditingThumbnail && (
                    <div
                        className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50"
                        onClick={(e) => {
                            if (e.target === e.currentTarget) {
                                setIsEditingThumbnail(false);
                                setNewThumbnailFile(null);
                            }
                        }}
                    >
                        <div className="bg-[#303261] p-8 rounded-lg w-full max-w-md">
                            <h2 className="text-2xl font-semibold mb-4 text-white">Edit Thumbnail</h2>
                            <input
                                type="file"
                                accept="image/*"
                                onChange={(e) => {
                                    const file = e.target.files[0];
                                    if (file) {
                                        if (file.type.startsWith('image/')) {
                                            setNewThumbnailFile(file);
                                        } else {
                                            Swal.fire({
                                                icon: 'error',
                                                title: 'Invalid File Type',
                                                text: 'Please select a valid image file.',
                                            });
                                            e.target.value = ''; // Reset the input value
                                        }
                                    }
                                }}
                                className="w-full px-4 py-3 border border-[#3c395d] rounded-xl text-xl bg-white text-black
                                    focus:ring-2 focus:ring-[#574bfc] focus:border-[#574bfc] focus:outline-none"
                            />
                            <div className="flex justify-center">
                                <button
                                    onClick={handleSaveThumbnail}
                                    className="bg-[#4954a5] text-white hover:bg-[#574bfc] text-2xl py-3 px-6 rounded-full mt-5"
                                >
                                    Save
                                </button>
                            </div>
                        </div>
                    </div>
                )}
                <div className="text-white">
                    <div className="flex mb-4">
                        <h2
                            className="md:text-5xl text-2xl font-semibold break-words min-w-0"
                            style={{ fontFamily: 'Poppins, sans-serif' }}
                        >
                            {title}
                        </h2>
                        <button
                            onClick={() => setIsEditingTitle(true)}
                            className="ml-4 text-white hover:text-gray-300 flex-shrink-0"
                        >
                            <FaPen />
                        </button>
                    </div>

                    {/* Edit Title Modal */}
                    {isEditingTitle && (
                        <div
                            className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50"
                            onClick={(e) => {
                                if (e.target === e.currentTarget) {
                                    setIsEditingTitle(false);
                                    setNewTitle('');
                                }
                            }}
                        >
                            <div className="bg-[#303261] p-8 rounded-lg w-full max-w-md">
                                <h2 className="text-2xl font-semibold mb-4 text-white">Edit Title</h2>
                                <input


                                    type="text"
                                    value={newTitle}
                                    onChange={(e) => setNewTitle(e.target.value)}
                                    className="w-full px-4 py-3 border border-[#3c395d] rounded-xl text-xl bg-white text-black placeholder-[#96a1b7]
                                        focus:ring-2 focus:ring-[#574bfc] focus:border-[#574bfc] focus:outline-none"
                                />
                                <div className="flex justify-center">
                                    <button
                                        onClick={handleSaveTitle}
                                        className="bg-[#4954a5] text-white hover:bg-[#574bfc] text-2xl py-3 px-6 rounded-full mt-5"
                                    >
                                        Save
                                    </button>
                                </div>
                            </div>
                        </div>
                    )}


                    <div className="mb-6">
                        <div className="flex mb-2">
                            <p className="md:text-lg text-base tracking-wide break-words  min-w-0">
                                <strong>Description:</strong> {description || '-'}
                            </p>
                            <button
                                onClick={() => {
                                    setIsEditingDescription(true);
                                    setNewDescription('');
                                }}
                                className="ml-2 text-white hover:text-gray-300 flex-shrink-0"
                            >
                                <FaPen />
                            </button>
                        </div>
                    </div>


                    {/* Edit Description Modal */}
                    {isEditingDescription && (
                        <div
                            className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50"
                            onClick={(e) => {
                                if (e.target === e.currentTarget) {
                                    setIsEditingDescription(false);
                                    setNewDescription('');
                                }
                            }}
                        >
                            <div className="bg-[#303261] p-8 rounded-lg w-full max-w-md">
                                <h2 className="text-2xl font-semibold mb-4 text-white">Edit Description</h2>
                                <textarea

                                    value={newDescription}
                                    onChange={(e) => setNewDescription(e.target.value)}
                                    className="w-full h-40 px-4 py-3 border border-[#3c395d] rounded-xl text-xl bg-white text-black placeholder-[#96a1b7]
                                        focus:ring-2 focus:ring-[#574bfc] focus:border-[#574bfc] focus:outline-none resize-none"
                                />
                                <div className="flex justify-center">
                                    <button
                                        onClick={handleSaveDescription}
                                        className="bg-[#4954a5] text-white hover:bg-[#574bfc] text-2xl py-3 px-6 rounded-full mt-5"
                                    >
                                        Save
                                    </button>
                                </div>
                            </div>
                        </div>
                    )}
                    {/* Event Date & Time */}
                    <div className="mb-6">
                        <div className="flex items-center">
                            <p className="md:text-lg text-base mb-2 tracking-wide">
                                <strong>Date:</strong> {formatDate(date)}
                            </p>
                            <button onClick={() => setIsEditingDate(true)} className="ml-2 text-white hover:text-gray-300">
                                <FaPen />
                            </button>
                        </div>

                        {/* Edit Date Modal */}
                        {isEditingDate && (
                            <div
                                className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50"
                                onClick={(e) => {
                                    if (e.target === e.currentTarget) {
                                        setIsEditingDate(false);
                                        setNewDate('');
                                    }
                                }}
                            >
                                <div className="bg-[#303261] p-8 rounded-lg w-full max-w-md">
                                    <h2 className="text-2xl font-semibold mb-4 text-white">Edit Date</h2>
                                    <div className="relative">
                                        <input
                                            type="date"
                                            value={newDate}
                                            onChange={(e) => setNewDate(e.target.value)}
                                            className="w-full px-4 py-3 border border-[#3c395d] text-xl rounded-xl bg-white text-black placeholder-[#96a1b7]
                                            focus:ring-2 focus:ring-[#574bfc] focus:border-[#574bfc] focus:outline-none appearance-none"
                                        />
                                        <FaCalendarAlt className="absolute right-4 top-3 text-[#96a1b7] pointer-events-none" />
                                    </div>
                                    <div className="flex justify-center">
                                        <button
                                            onClick={handleSaveDate}
                                            className="bg-[#4954a5] text-white hover:bg-[#574bfc] text-2xl py-3 px-6 rounded-full mt-5"
                                        >
                                            Save
                                        </button>
                                    </div>
                                </div>
                            </div>
                        )}

                        <div className="flex items-center">
                            <p className="md:text-lg text-base mb-2 tracking-wide">
                                <strong>Time:</strong> {time}
                            </p>
                            <button onClick={() => setIsEditingTime(true)} className="ml-2 text-white hover:text-gray-300">
                                <FaPen />
                            </button>
                        </div>

                        {/* Edit Time Modal */}
                        {isEditingTime && (
                            <div
                                className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50"
                                onClick={(e) => {
                                    if (e.target === e.currentTarget) {
                                        setIsEditingTime(false);
                                        setNewStartTime('');
                                        setNewEndTime('');
                                    }
                                }}
                            >
                                <div className="bg-[#303261] p-8 rounded-lg w-full max-w-md">
                                    <h2 className="text-2xl font-semibold mb-4 text-white">Edit Time</h2>
                                    <div className="mb-4">
                                        <label className="block text-white mb-2">Start Time</label>
                                        <div className="relative">
                                            <input
                                                type="time"
                                                value={newStartTime}
                                                onChange={(e) => setNewStartTime(e.target.value)}
                                                className="w-full px-4 py-3 border border-[#3c395d] text-xl rounded-xl bg-white text-black placeholder-[#96a1b7]
                                                focus:ring-2 focus:ring-[#574bfc] focus:border-[#574bfc] focus:outline-none appearance-none"
                                            />
                                            <FaClock className="absolute right-4 top-3 text-[#96a1b7] pointer-events-none" />
                                        </div>
                                    </div>
                                    <div className="mb-4">
                                        <label className="block text-white mb-2">End Time</label>
                                        <div className="relative">
                                            <input
                                                type="time"
                                                value={newEndTime}
                                                onChange={(e) => setNewEndTime(e.target.value)}
                                                className="w-full px-4 py-3 border border-[#3c395d] text-xl rounded-xl bg-white text-black placeholder-[#96a1b7]
                                                focus:ring-2 focus:ring-[#574bfc] focus:border-[#574bfc] focus:outline-none appearance-none"
                                            />
                                            <FaClock className="absolute right-4 top-3 text-[#96a1b7] pointer-events-none" />
                                        </div>
                                    </div>
                                    <div className="flex justify-center">
                                        <button
                                            onClick={handleSaveTime}
                                            className="bg-[#4954a5] text-white hover:bg-[#574bfc] text-2xl py-3 px-6 rounded-full mt-5"
                                        >
                                            Save
                                        </button>
                                    </div>
                                </div>
                            </div>
                        )}
                    </div>
                    <div className="mb-5">
                        <h3 className="text-2xl font-semibold text-white mb-4">Ticket Variations</h3>
                        <div className="space-y-4">
                            {ticketVariations.map((variation) => (
                                <div
                                    key={variation.id}
                                    className="border-2 p-4 rounded-lg text-white flex justify-between items-center"
                                >
                                    <div>
                                        <p className="md:text-lg text-base mb-2 tracking-wide">

                                            <strong>Type:</strong> {variation.type}
                                        </p>
                                        <p className="md:text-lg text-base mb-2 tracking-wide">

                                            <strong>Price:</strong> {variation.price} {currency}
                                        </p>
                                        <p className="md:text-lg text-base mb-2 tracking-wide">

                                            <strong>Quantity:</strong> {variation.quantity}
                                        </p>
                                    </div>
                                    <button onClick={() => handleEditTicket(variation)} className="ml-2 text-white hover:text-gray-300 mb-4">
                                        <FaPen />
                                    </button>
                                </div>
                            ))}
                        </div>
                    </div>

                    {/* Edit Ticket Modal */}
                    {isEditingTickets && (
                        <div
                            className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50"
                            onClick={(e) => {
                                if (e.target === e.currentTarget) {
                                    setIsEditingTickets(false);
                                    setCurrentTicket(null);
                                }
                            }}
                        >
                            <div className="bg-[#303261] p-8 rounded-lg w-full max-w-md">
                                <h2 className="text-2xl font-semibold mb-4 text-white">Edit Ticket</h2>
                                <input
                                    type="text"
                                    placeholder="Type"
                                    value={updatedTicketData.type || ''}
                                    onChange={(e) =>
                                        setUpdatedTicketData({ ...updatedTicketData, type: e.target.value })
                                    }
                                    className="w-full px-4 py-3 mb-4 border border-[#3c395d] rounded-xl text-xl bg-white text-black placeholder-[#96a1b7]
                focus:ring-2 focus:ring-[#574bfc] focus:border-[#574bfc] focus:outline-none"
                                />
                                <input
                                    type="number"
                                    placeholder="Price"
                                    value={updatedTicketData.price || ''}
                                    min="0.1"
                                    step="0.1"
                                    onChange={(e) => {
                                        const inputValue = parseFloat(e.target.value); // Parse the value as a float
                                        if (isNaN(inputValue) || inputValue <= 0) {
                                            setUpdatedTicketData({ ...updatedTicketData, price: '' }); // Reset to empty if invalid
                                        } else {
                                            setUpdatedTicketData({ ...updatedTicketData, price: inputValue }); // Update with valid float
                                        }
                                    }}
                                    className="w-full px-4 py-3 mb-4 border border-[#3c395d] rounded-xl text-xl bg-white text-black placeholder-[#96a1b7]
        focus:ring-2 focus:ring-[#574bfc] focus:border-[#574bfc] focus:outline-none"
                                />

                                <input
                                    type="number"
                                    placeholder="Quantity"
                                    value={updatedTicketData.quantity || ''}
                                    min="1"

                                    onChange={(e) => {
                                        const inputValue = parseInt(e.target.value, 10) || '';
                                        if (inputValue === '' || inputValue > 0) {
                                            setUpdatedTicketData({ ...updatedTicketData, quantity: inputValue });
                                        }
                                    }}
                                    className="w-full px-4 py-3 border border-[#3c395d] rounded-xl text-xl bg-white text-black placeholder-[#96a1b7]
                focus:ring-2 focus:ring-[#574bfc] focus:border-[#574bfc] focus:outline-none"
                                />
                                <div className="flex justify-center space-x-4">

                                    <button
                                        onClick={() => {
                                            if (!updatedTicketData.type || updatedTicketData.quantity < 1 || !updatedTicketData.price) {
                                                Swal.fire({
                                                    icon: 'error',
                                                    title: 'Invalid Input',
                                                    text: 'Fill all the fields',
                                                });
                                            } else {
                                                handleSaveTicket();
                                            }
                                        }}
                                        className="bg-[#4954a5] text-white hover:bg-[#574bfc] text-2xl py-3 px-6 rounded-full mt-5"
                                    >
                                        Save
                                    </button>
                                </div>
                            </div>
                        </div>
                    )}

                    {/* Venue and Pricing */}
                    <div className="grid grid-cols-2 gap-4">
                        <div>
                            <div>
                                <div className="flex mb-2">
                                    <p className="md:text-lg text-base tracking-wide break-words min-w-0">
                                        <strong>Venue:</strong> {venue}
                                    </p>
                                    <button onClick={() => setIsEditingVenue(true)} className="ml-2 text-white hover:text-gray-300 flex-shrink-0">
                                        <FaPen />
                                    </button>
                                </div>
                                {/* ... */}
                            </div>


                            {/* Edit Venue Modal */}
                            {isEditingVenue && (
                                <div
                                    className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50"
                                    onClick={(e) => {
                                        if (e.target === e.currentTarget) {
                                            setIsEditingVenue(false);
                                            setNewVenue('');
                                        }
                                    }}
                                >
                                    <div className="bg-[#303261] p-8 rounded-lg w-full max-w-md">
                                        <h2 className="text-2xl font-semibold mb-4 text-white">Edit Venue</h2>
                                        <input

                                            type="text"
                                            value={newVenue}
                                            onChange={(e) => setNewVenue(e.target.value)}
                                            className="w-full px-4 py-3 border border-[#3c395d] rounded-xl text-xl bg-white text-black placeholder-[#96a1b7]
                                                focus:ring-2 focus:ring-[#574bfc] focus:border-[#574bfc] focus:outline-none"
                                        />
                                        <div className="flex justify-center">
                                            <button
                                                onClick={handleSaveVenue}
                                                className="bg-[#4954a5] text-white hover:bg-[#574bfc] text-2xl py-3 px-6 rounded-full mt-5"
                                            >
                                                Save
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>

                    {/* DJ and Tickets Info */}
                    <div className="grid grid-cols-2 gap-4 mt-4">
                        <div>
                            <div className="flex">
                                <p className="md:text-lg text-base tracking-wide break-words min-w-0">
                                    <strong>DJ Name/Presenter:</strong> {djname}
                                </p>
                                <button onClick={() => setIsEditingDjName(true)} className="ml-2 text-white hover:text-gray-300">
                                    <FaPen />
                                </button>
                            </div>

                            {/* Edit DJ Name Modal */}
                            {isEditingDjName && (
                                <div
                                    className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50"
                                    onClick={(e) => {
                                        if (e.target === e.currentTarget) {
                                            setIsEditingDjName(false);
                                            setNewDjName('');
                                        }
                                    }}
                                >
                                    <div className="bg-[#303261] p-8 rounded-lg w-full max-w-md">
                                        <h2 className="text-2xl font-semibold mb-4 text-white">Edit DJ Name</h2>
                                        <input
                                            type="text"
                                            value={newDjName}
                                            onChange={(e) => setNewDjName(e.target.value)}
                                            className="w-full px-4 py-3 border border-[#3c395d] rounded-xl text-xl bg-white text-black placeholder-[#96a1b7]
                                                focus:ring-2 focus:ring-[#574bfc] focus:border-[#574bfc] focus:outline-none"
                                        />
                                        <div className="flex justify-center">
                                            <button
                                                onClick={handleSaveDjName}
                                                className="bg-[#4954a5] text-white hover:bg-[#574bfc] text-2xl py-3 px-6 rounded-full mt-5"
                                            >
                                                Save
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            )}
                        </div>
                        <div>


                        </div>
                        <div className="flex items-center">
                            <button
                                className="md:text-lg text-base tracking-wide underline text-[#574bfc] hover:text-[#cc15ec]"
                                onClick={openAttendeesModal}
                            >
                                <strong>Attending</strong>
                            </button>
                            <p className="md:text-lg text-base tracking-wide ml-2">
                                <strong>:</strong> {attending}
                            </p>
                        </div>
                    </div>
                    <div className="flex items-center mt-6">
                        <button
                            className="md:text-lg text-base tracking-wide underline text-[#574bfc] hover:text-[#cc15ec]"
                            onClick={openRevenueModal}
                        >
                            <strong>View Ticket Revenue</strong>
                        </button>
                    </div>

                    <div className="flex items-center mt-6">
                        <p className="md:text-lg text-base">
                            <strong>City:</strong> {city}
                        </p>
                        <button onClick={() => setIsEditingCity(true)} className="ml-2 text-white hover:text-gray-300">
                            <FaPen />
                        </button>
                    </div>

                    {/* Edit City Modal */}
                    {isEditingCity && (
                        <div
                            className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50"
                            onClick={(e) => {
                                if (e.target === e.currentTarget) {
                                    setIsEditingCity(false);
                                    setNewCity('');
                                }
                            }}
                        >
                            <div className="bg-[#303261] p-8 rounded-lg w-full max-w-md">
                                <h2 className="text-2xl font-semibold mb-4 text-white">Edit City</h2>
                                <input
                                    type="text"
                                    value={newCity}
                                    onChange={(e) => setNewCity(e.target.value)}
                                    className="w-full px-4 py-3 border border-[#3c395d] rounded-xl text-xl bg-white text-black placeholder-[#96a1b7]
                                        focus:ring-2 focus:ring-[#574bfc] focus:border-[#574bfc] focus:outline-none"
                                />
                                <div className="flex justify-center">
                                    <button
                                        onClick={handleSaveCity}
                                        className="bg-[#4954a5] text-white hover:bg-[#574bfc] text-2xl py-3 px-6 rounded-full mt-5"
                                    >
                                        Save
                                    </button>
                                </div>
                            </div>
                        </div>
                    )}
                    <div className="flex items-center mt-2">
                        <p className="md:text-lg text-base">
                            <strong>State:</strong> {state || '-'}
                        </p>
                        <button
                            onClick={() => {
                                setIsEditingState(true);
                                setNewState(state || '');
                            }}
                            className="ml-2 text-white hover:text-gray-300"
                        >
                            <FaPen />
                        </button>
                    </div>

                    {/* Edit State Modal */}
                    {isEditingState && (
                        <div
                            className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50"
                            onClick={(e) => {
                                if (e.target === e.currentTarget) {
                                    setIsEditingState(false);
                                    setNewState('');
                                }
                            }}
                        >
                            <div className="bg-[#303261] p-8 rounded-lg w-full max-w-md">
                                <h2 className="text-2xl font-semibold mb-4 text-white">Edit State</h2>
                                <input
                                    type="text"
                                    value={newState}
                                    onChange={(e) => setNewState(e.target.value)}
                                    className="w-full px-4 py-3 border border-[#3c395d] rounded-xl text-xl bg-white text-black placeholder-[#96a1b7]
                                        focus:ring-2 focus:ring-[#574bfc] focus:border-[#574bfc] focus:outline-none"
                                />
                                <div className="flex justify-center">
                                    <button
                                        onClick={handleSaveState}
                                        className="bg-[#4954a5] text-white hover:bg-[#574bfc] text-2xl py-3 px-6 rounded-full mt-5"
                                    >
                                        Save
                                    </button>
                                </div>
                            </div>
                        </div>
                    )}

                    {/* Show Country */}
                    <div className="flex items-center mt-2">
                        <p className="md:text-lg text-base">
                            <strong>Country:</strong> {country}
                        </p>
                        <button
                            onClick={() => {
                                setIsEditingCountry(true);
                                setNewCountry('');
                            }}
                            className="ml-2 text-white hover:text-gray-300"
                        >
                            <FaPen />
                        </button>
                    </div>

                    {/* Edit Country Modal */}
                    {isEditingCountry && (
                        <div
                            className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50"
                            onClick={(e) => {
                                if (e.target === e.currentTarget) {
                                    setIsEditingCountry(false);
                                    setNewCountry('');
                                }
                            }}
                        >
                            <div className="bg-[#303261] p-8 rounded-lg w-full max-w-md">
                                <h2 className="text-2xl font-semibold mb-4 text-white">Edit Country</h2>
                                <input
                                    type="text"
                                    value={newCountry}
                                    onChange={(e) => setNewCountry(e.target.value)}
                                    className="w-full px-4 py-3 border border-[#3c395d] rounded-xl text-xl bg-white text-black placeholder-[#96a1b7]
                                        focus:ring-2 focus:ring-[#574bfc] focus:border-[#574bfc] focus:outline-none"
                                />
                                <div className="flex justify-center">
                                    <button
                                        onClick={handleSaveCountry}
                                        className="bg-[#4954a5] text-white hover:bg-[#574bfc] text-2xl py-3 px-6 rounded-full mt-5"
                                    >
                                        Save
                                    </button>
                                </div>
                            </div>
                        </div>
                    )}

                    <div className="flex items-center mt-2">
                        <a
                            href={location}
                            className="text-[#574bfc] md:text-lg text-base underline hover:text-[#cc15ec]"
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            <strong>Show Location</strong>
                        </a>
                        <button onClick={() => setIsEditingLocation(true)} className="ml-2 text-white hover:text-gray-300">
                            <FaPen />
                        </button>
                    </div>

                    {/* Edit Location Modal */}
                    {isEditingLocation && (
                        <div
                            className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50"
                            onClick={(e) => {
                                if (e.target === e.currentTarget) {
                                    setIsEditingLocation(false);
                                    setNewLocation('');
                                }
                            }}
                        >
                            <div className="bg-[#303261] p-8 rounded-lg w-full max-w-md">
                                <h2 className="text-2xl font-semibold mb-4 text-white">Edit Location</h2>
                                <input
                                    type="text"
                                    value={newLocation}
                                    onChange={(e) => setNewLocation(e.target.value)}
                                    placeholder="Enter Google Maps link"
                                    className="w-full px-4 py-3 border border-[#3c395d] rounded-xl text-xl bg-white text-black placeholder-[#96a1b7]
                                        focus:ring-2 focus:ring-[#574bfc] focus:border-[#574bfc] focus:outline-none"
                                />
                                <div className="flex justify-center">
                                    <button
                                        onClick={handleSaveLocation}
                                        className="bg-[#4954a5] text-white hover:bg-[#574bfc] text-2xl py-3 px-6 rounded-full mt-5"
                                    >
                                        Save
                                    </button>
                                </div>
                            </div>
                        </div>
                    )}

                    {/* Genres */}
                    <div className="mt-8">
                        <div className="flex items-center">
                            <h3 className="md:text-2xl text-lg font-semibold tracking-wider mb-4" style={{ fontFamily: 'Poppins, sans-serif' }}>
                                Genres
                            </h3>
                            <button onClick={() => setIsEditingGenre(true)} className="ml-2 text-white hover:text-gray-300">
                                <FaPen />
                            </button>
                        </div>

                        {/* Edit Genre Modal */}
                        {isEditingGenre && (
                            <div
                                className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50"
                                onClick={(e) => {
                                    if (e.target === e.currentTarget) {
                                        setIsEditingGenre(false);
                                        setNewGenre('');
                                    }
                                }}
                            >
                                <div className="bg-[#303261] p-8 rounded-lg w-full max-w-md">
                                    <h2 className="text-2xl font-semibold mb-4 text-white">Edit Genres</h2>
                                    <input
                                        type="text"
                                        value={newGenre}
                                        onChange={(e) => setNewGenre(e.target.value)}
                                        placeholder="If multiple, separate by commas"
                                        className="w-full px-4 py-3 border border-[#3c395d] rounded-xl text-xl bg-white text-black placeholder-[#96a1b7]
                                            focus:ring-2 focus:ring-[#574bfc] focus:border-[#574bfc] focus:outline-none"
                                    />
                                    <div className="flex justify-center">
                                        <button
                                            onClick={handleSaveGenre}
                                            className="bg-[#4954a5] text-white hover:bg-[#574bfc] text-2xl py-3 px-6 rounded-full mt-5"
                                        >
                                            Save
                                        </button>
                                    </div>
                                </div>
                            </div>
                        )}

                        <div className="flex flex-wrap gap-2">
                            {genre.split(',').map((tag, index) => (
                                <span
                                    key={index}
                                    className="px-3 py-1 bg-[#574bfc] text-sm font-medium rounded-full"
                                    style={{ fontFamily: 'Poppins, sans-serif', letterSpacing: '0.5px' }}
                                >
                                    {tag.trim()}
                                </span>
                            ))}
                        </div>
                        <div className="flex items-center mt-4">

                            <button
                                onClick={downloadOrdersHandler}
                                className="text-[#574bfc] md:text-lg text-base underline hover:text-[#cc15ec]"
                            >
                                Download Orders
                            </button>
                        </div>
                        <div className="flex items-center mt-4">

                            <button
                                onClick={deletePostHandler}
                                className="text-[#574bfc] md:text-lg text-base underline hover:text-[#cc15ec]"
                            >
                                Delete Post
                            </button>
                        </div>
                        <div className="flex items-center mt-4">

                            <button
                                onClick={openRefundModal}
                                className="text-[#574bfc] md:text-lg text-base underline hover:text-[#cc15ec]"
                            >
                                Delete Ticket
                            </button>
                        </div>
                    </div>
                </div>
                {isRefundModalOpen && (
                    <div
                        className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50"
                        onClick={(e) => {
                            if (e.target === e.currentTarget) {
                                closeRefundModal();
                            }
                        }}
                    >
                        <div className="bg-[#303261] p-8 rounded-lg w-full max-w-md">
                            <h2 className="text-2xl font-semibold mb-4 text-white">Delete Ticket</h2>

                            <input
                                type="text"
                                value={transactionReference}
                                onChange={(e) => setTransactionReference(e.target.value)}
                                placeholder="Transaction Reference"
                                className="w-full px-4 py-3 border border-[#3c395d] rounded-xl text-xl bg-white text-black placeholder-[#96a1b7]
                                    focus:ring-2 focus:ring-[#574bfc] focus:border-[#574bfc] focus:outline-none"
                            />
                            <div className="flex justify-center space-x-4 mt-6">
                                <button
                                    onClick={handleRefund}
                                    className="bg-[#4954a5] text-white hover:bg-[#574bfc] text-xl py-2 px-6 rounded-full"
                                >
                                    Delete
                                </button>

                            </div>
                        </div>
                    </div>
                )}
                {/* Attendees Modal */}
                {isAttendeesModalOpen && (
                    <AttendeesModal eventId={id} eventTitle={title} onClose={closeAttendeesModal} />
                )}
                {isRevenueModalOpen && (
                    <TicketRevenueModal
                        eventId={event.id}
                        eventTitle={event.title}
                        onClose={closeRevenueModal}
                    />
                )}
            </div>
        </div>
    );
};

export default EventAdminModal;
